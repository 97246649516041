import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { SerializedBigNumber } from 'types/1delta'
import { Call, multicallViem } from 'utils/multicall'
import AAVE_POOL_AND_DATA_PROVIDER_ABI from 'abis/aave/AAVEPoolAndDataProvider.json'
import STABLE_DEBT_TOKEN from 'abis/aave/StableDebtToken.json'
import { TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { SupportedChainId } from 'constants/chains'
import { getAaveV2TokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { getAddressesForChainIdFromAssetDict } from 'hooks/1delta/addresses'
import { Lender } from 'types/lenderData/base'
import { ONE_DELTA_COMPOSER } from 'hooks/1delta/addresses1Delta'
import { addressesAaveV2ATokens, addressesAaveV2Core, addressesAaveV2STokens, addressesAaveV2VTokens } from 'hooks/1delta/addressesAaveV2'
import { getLenderAssets } from 'constants/getAssets'
import { getAaveTypePoolDataProviderAddress } from 'hooks/1delta'

const fallbackDebtToken = '0x52A1CeB68Ee6b7B5D13E0376A1E0E4423A8cE26e'

export enum AaveV2TypeGetUserReserveData {
  currentATokenBalance = 0,
  currentStableDebt,
  currentVariableDebt,
  principalStableDebt,
  scaledVariableDebt,
  stableBorrowRate,
  liquidityRate,
  stableRateLastUpdated,
  usageAsCollateralEnabled,
}

interface AaveV2UserReserveResponse {
  chainId: number
  account: string
  tokensData: {
    [tokenSymbol: string]: {
      // token amounts
      deposits: number
      debt: number
      debtStable: number
      // usd amounts
      depositsUSD: number
      debtUSD: number
      debtStableUSD: number

      collateralActive: boolean
      claimableRewards: number
    }
  },
  allowances: { [asset: string]: AllowanceData }
}

interface AllowanceData {
  allowanceDepositDirect: SerializedBigNumber
  allowanceWithdrawal: SerializedBigNumber
  allowanceBorrowingVariable: SerializedBigNumber
  allowanceBorrowingStable: SerializedBigNumber
}

interface AaveV2UserReservesQueryParams {
  chainId: number
  account: string
  prices: { [asset: string]: number }
}

export const fetchAaveV2UserData: AsyncThunk<
  AaveV2UserReserveResponse,
  AaveV2UserReservesQueryParams,
  any
> = createAsyncThunk<AaveV2UserReserveResponse, AaveV2UserReservesQueryParams>(
  'aave-v2/fetchAaveV2UserData',

  async ({ chainId, account, prices }) => {
    // catch invalid inputs
    if (!account || chainId !== SupportedChainId.POLYGON) {
      return { chainId, tokensData: {}, lendRewards: 0, allowances: {}, account: '' }
    }
    const assetsToQuery = getLenderAssets(chainId, Lender.AAVE_V2)
    const providerAddress = getAaveTypePoolDataProviderAddress(chainId, Lender.AAVE_V2)
    const aaveV2Tokens = getAaveV2TokenAddresses(chainId)
    const assets = assetsToQuery.map((a) => aaveV2Tokens[a])
    const names = assetsToQuery
    const calls: Call[] = assets.map((tk) => {
      return {
        address: providerAddress,
        name: 'getUserReserveData',
        params: [tk, account],
      }
    })

    const lTokens = getAddressesForChainIdFromAssetDict(addressesAaveV2ATokens, chainId, Lender.AAVE_V2)
    const vTokens = getAddressesForChainIdFromAssetDict(addressesAaveV2VTokens, chainId, Lender.AAVE_V2)
    const sTokens = getAddressesForChainIdFromAssetDict(
      addressesAaveV2STokens,
      chainId,
      Lender.AAVE_V2
    )

    let lAndVTokensAddresses: string[] = []

    // rewards are accrued for both l and v tokens
    names.forEach((name) => {
      lAndVTokensAddresses.push(lTokens[name], vTokens[name])
    })

    // allowances


    const broker = ONE_DELTA_COMPOSER[chainId]
    const aavePool = addressesAaveV2Core.PoolProxy[chainId]

    const callsDirect: Call[] = names.map((tk) => {
      return {
        address: aaveV2Tokens[tk],
        name: 'allowance',
        params: [account, aavePool],
      }
    })
    const callsWithdraw: Call[] = names.map((tk) => {
      return {
        address: lTokens[tk],
        name: 'allowance',
        params: [account, broker],
      }
    })
    const callsBorrowVariable: Call[] = names.map((tk) => {
      return {
        address: vTokens[tk] ?? fallbackDebtToken,
        name: 'borrowAllowance',
        params: [account, broker],
      }
    })
    const callsBorrowStable: Call[] = names.map((tk) => {
      return {
        address: sTokens[tk] ?? fallbackDebtToken,
        name: 'borrowAllowance',
        params: [account, broker],
      }
    })

    let ABI = [
      ...AAVE_POOL_AND_DATA_PROVIDER_ABI,
      ...STABLE_DEBT_TOKEN
    ]
    ABI = ABI.filter((v, i, a) => a.findIndex(t => (t.name === v.name && t.type === v.type)) === i)

    let multicallResult: any[] = []
    try {
      multicallResult = await multicallViem(
        chainId,
        ABI,
        [
          ...calls,
          ...callsDirect, ...callsWithdraw, ...callsBorrowVariable, ...callsBorrowStable
        ],
        0, // primary
      )
    } catch (e: any) {
      console.log("Error fetchung user data:", e)
      return { chainId, tokensData: {}, lendRewards: 0, allowances: {}, account: '' }
    }

    const callsLength = calls.length

    // map claimable rewards to tokens

    const tokensData: any = Object.assign(
      {},
      ...multicallResult.slice(0, callsLength).map((entry: any, index) => {
        const asset = names[index]
        const decimals = TOKEN_META[asset]?.decimals ?? 18
        const price = prices[asset]
        const deposits = parseRawAmount(entry?.[AaveV2TypeGetUserReserveData.currentATokenBalance].toString(), decimals)
        const debtStable = parseRawAmount(entry?.[AaveV2TypeGetUserReserveData.currentStableDebt].toString(), decimals)
        const debt = parseRawAmount(entry?.[AaveV2TypeGetUserReserveData.currentVariableDebt].toString(), decimals)
        return {
          [asset]: {
            // raw amounts
            deposits,
            debtStable,
            debt,
            // USD amounts
            depositsUSD: deposits * price,
            debtStableUSD: debtStable * price,
            debtUSD: debt * price,
            collateralActive: Boolean(entry?.[AaveV2TypeGetUserReserveData.usageAsCollateralEnabled]),
            claimableRewards: 0,
          },
        }
      })
    )

    // allowances
    let start = callsLength
    const tokensCount = names.length
    const directResults = multicallResult.slice(start, start + tokensCount)
    start += tokensCount
    const collateralResults = multicallResult.slice(start, start + tokensCount)
    start += tokensCount
    const delegationVariableResults = multicallResult.slice(start, start + tokensCount)
    start += tokensCount
    const delegationStableResults = multicallResult.slice(start, start + tokensCount)
    // create data
    const allowances: any = Object.assign(
      {},
      ...names.map((name, index) => {
        return {
          [name]: {
            allowanceDepositDirect: directResults[index]?.toString(),
            allowanceWithdrawal: collateralResults[index]?.toString(),
            allowanceBorrowingVariable: delegationVariableResults[index]?.toString(),
            allowanceBorrowingStable: delegationStableResults[index]?.toString(),
          }
        }
      })
    )

    return {
      chainId,
      account,
      tokensData,
      allowances
    }
  }
)