// lender abis
import AAVE_POOL_ABI from 'abis/aave/AAVEPoolV3.json'
import COMPOUND_COMPTROLLER_ABI from 'abis/compound-v2/Comptroller.json'
import VENUS_COMPTROLLER_ABI from 'abis/venus/Comptroller.json'
import COMET_ABI from 'abis/compound-v3/Comet.json'
import COMET_EXT_ABI from 'abis/compound-v3/CometExt.json'
import COMET_REWARDS_ABI from 'abis/compound-v3/CometRewards.json'
import INCENTIVES_CONTROLLER_ABI from 'abis/lendle/IncentivesController.json'
import PULL_INCENTIVES_CONTROLLER_ABI from 'abis/meridian/PullRewardsIncentivesController.json'
import AURELIUS_REWARDER_ABI from 'abis/aurelius/rewarder.json'
import POS_MANAGER_ABI from 'abis/init/posManager.json'
import INIT_CORE_ABI from 'abis/init/core.json'

// oracles
import ORACLE_ABI from 'abis/aave/AAVEOracle.json'
import MANTLE_ORACLE_ABI from 'abis/lendle/Oracle.json'
import GHO_ORACLE from 'abis/aave/ghoOracle.json'

// lens contracts
import COMPOUND_LENS_CLASSIC_ABI from 'abis/compound-v2/CompoundLens.json'
import OVIX_LENS_CLASSIC_ABI from 'abis/compound-v2/OVixLens.json'
import COMET_LENS_ABI from 'abis/compound-v3/CometLens.json'
import VENUS_LENS_CLASSIC_ABI from 'abis/venus/Lens.json'
import INIT_LENS_ABI from 'abis/init/initLens.json'

// 1delta abis
import STABLE_DEBT_TOKEN from 'abis/aave/StableDebtToken.json'

import BROKER_PROXY_ABI from 'abis/BrokerProxy.json'

// composer
import COMPOSER from 'abis/gen2/Composer.json'

// erc20 - the classic
import ERC20_ABI from 'abis/erc20.json'

import { getContract } from 'utils'
import { Contract } from 'ethers'
import { RPC_PROVIDERS, getProviderByIndex } from '../../constants/providers'
import { SupportedChainId } from 'constants/chains'
import { ONE_DELTA_COMPOSER, initBrokerAddresses } from './addresses1Delta'
import { addressesAaveCore } from './addressesAave'
import { compoundAddresses } from './addressesCompound'
import { oVixAddresses } from './addresses0Vix'
import { ETHEREUM_CHAINS, POLYGON_CHAINS } from 'constants/1delta'
import {
  AAVEPoolV3,
  BrokerProxy,
  Comet,
  CometRewards,
  Core,
  Erc20,
  IncentivesController,
  InitLens,
  PosManager,
  VariableDebtToken,
  Composer,
  PullRewardsIncentivesController
} from 'abis/types'
import { GhoOracle } from 'abis/types/GhoOracle'
import { CometLens } from 'abis/types/CometLens'
import { addressesCompoundV3Core } from './addressesCompoundV3'
import { MarginTradeType, SupportedAssets } from 'types/1delta'
import { useMemo } from 'react'
import { CometExt } from 'abis/types/CometExt'
import { venusAddresses } from './addressesVenus'
import { addressesLendleCore } from './addressesLendle'
import { Lender } from 'types/lenderData/base'
import { useWeb3ReactWrapped } from 'hooks/web3'
import { addressesInitCore } from './addressesInit'
import { getAaveTypeIncentivesControllerAddress, getAaveTypePoolAddress } from '.'
import { addressesMeridianCore } from './addressesMeridian'
import { addressesAureliusCore } from './addressesAurelius'

const defaultAddress = '0xBA4e9BbEa023AcaE6b9De0322A5b274414e4705C'

// account is optional
export function getModeSelectorContract(chainId: number, account?: string, library?: any, lender = Lender.AAVE_V3): Contract & (AAVEPoolV3 | Core) {
  if (lender === Lender.AAVE_V3)
    return getContract(addressesAaveCore.PoolProxy[chainId] ?? defaultAddress, AAVE_POOL_ABI, library, account) as AAVEPoolV3
  return getContract(addressesInitCore.Core[chainId] ?? defaultAddress, INIT_CORE_ABI, library, account) as Core
}

export function getInitLensContract(chainId: number): Contract & InitLens {
  return getContract(addressesInitCore.PublicLens[chainId] ?? defaultAddress, INIT_LENS_ABI, getProviderByIndex(chainId, 0)) as InitLens
}

export function getCometLensContract(chainId: number, account?: string, library?: any): CometLens {
  return getContract(addressesCompoundV3Core.lens[chainId] ?? defaultAddress, COMET_LENS_ABI, library, account) as CometLens
}

export function getCometContract(chainId: number, baseAsset: SupportedAssets, account?: string, library?: any): Comet {
  return getContract(addressesCompoundV3Core.comet[chainId]?.[baseAsset] ?? defaultAddress, COMET_ABI, library, account) as Comet
}

export function usePosManagerContract(chainId: number,): PosManager {
  const { provider, account } = useWeb3ReactWrapped()
  return getContract(addressesInitCore.PosManager[chainId] ?? defaultAddress, POS_MANAGER_ABI, provider as any, account) as PosManager
}

export function getCometExtContract(chainId: number, baseAsset: SupportedAssets, account?: string, library?: any): CometExt {
  return getContract(
    addressesCompoundV3Core.comet[chainId]?.[baseAsset] ?? defaultAddress,
    COMET_EXT_ABI,
    library,
    account
  ) as CometExt
}

export function getPosManagerContract(chainId: number, account?: string, library?: any): PosManager {
  return getContract(
    addressesInitCore.PosManager[chainId] ?? defaultAddress,
    POS_MANAGER_ABI,
    library,
    account
  ) as PosManager
}


export function getCometExtAddress(chainId: number, baseAsset: SupportedAssets): string {
  return addressesCompoundV3Core.cometExt[chainId][baseAsset] ?? defaultAddress
}

/**
 * 
 * @param chainId the network Id
 * @param protocol the lending protocol
 * @param tradeType trade type
 * @param relevantAccount wallet address for comet / aave, 1delta account address for compound v2 
 * @param baseAsset base asset for compound V3
 * @param isDirect direct flag (signals direct interaction with lender), makes only difference for comet / aave
 * @returns the contract
 */
export function useGetTradeContract(
  chainId: number,
  protocol: Lender,
  tradeType: MarginTradeType,
  relevantAccount?: string,
  baseAsset = SupportedAssets.USDCE,
  isDirect = false
): Contract {
  const { provider, account } = useWeb3ReactWrapped()
  return useMemo(() => {
    switch (tradeType) {
      case MarginTradeType.Open:
      case MarginTradeType.Close:
      case MarginTradeType.CollateralSwap:
      case MarginTradeType.DebtSwap:
        {
          switch (protocol) {
            case Lender.AAVE_V3:
            case Lender.AAVE_V2:
            case Lender.LENDLE:
            case Lender.AURELIUS:
            case Lender.COMPOUND_V3:
            case Lender.MERIDIAN: {
              return getContract(
                ONE_DELTA_COMPOSER[chainId] ?? defaultAddress,
                COMPOSER,
                provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
                account
              )
            }
            case Lender.INIT: {
              return getContract(
                initBrokerAddresses[chainId] ?? defaultAddress,
                BROKER_PROXY_ABI,
                provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
                account
              )
            }
            default: {
              return getContract(
                relevantAccount ?? defaultAddress,
                [],
                provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
                account
              )
            }
          }
        }
      default: {
        switch (protocol) {
          case Lender.AAVE_V3:
          case Lender.LENDLE: {
            if (isDirect)
              return getContract(
                addressesAaveCore.PoolProxy[chainId] ?? defaultAddress,
                AAVE_POOL_ABI,
                provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
                account
              )
            else
              return getContract(
                ONE_DELTA_COMPOSER[chainId] ?? defaultAddress,
                COMPOSER,
                provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
                account
              )
          }
          case Lender.COMPOUND_V2: {
            return getContract(
              relevantAccount ?? defaultAddress,
              [],
              provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
              account
            )
          }
          case Lender.COMPOUND_V3:
          default: {
            if (isDirect)
              return getContract(
                addressesCompoundV3Core.comet[chainId]?.[baseAsset] ?? defaultAddress,
                COMET_ABI,
                provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
                account
              )
            else
              return getContract(
                ONE_DELTA_COMPOSER[chainId] ?? defaultAddress,
                COMPOSER,
                provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
                account
              )
          }
        }
      }
    }
  },
    [
      chainId,
      protocol,
      tradeType,
      relevantAccount,
      baseAsset,
      isDirect,
      provider,
      account
    ])
}

/**
 * 
 * @param chainId the network Id
 * @param protocol the lending protocol
 * @param tradeType trade type (MarginTradeType)
 * @param relevantAccount wallet address for comet / aave, 1delta account address for compound v2 
 * @param baseAsset base asset for compound V3
 * @param isDirect direct flag (signals direct interaction with lender), makes only difference for comet / aave
 * @returns the contract
 */
export function useGetMoneyMarketTradeContracts(
  chainId: number,
  protocol: Lender,
  relevantAccount?: string,
  baseAsset = SupportedAssets.USDCE,
  isDirect = false
): [Contract, Contract] {
  const { provider, account } = useWeb3ReactWrapped()
  return useMemo(() => {
    switch (protocol) {
      case Lender.AAVE_V3:
      case Lender.LENDLE:
      case Lender.YLDR:
      case Lender.AAVE_V2:
      case Lender.AURELIUS:
      case Lender.MERIDIAN: {
        return [
          getContract(
            getAaveTypePoolAddress(chainId, protocol) ?? defaultAddress,
            AAVE_POOL_ABI,
            provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
            account
          ),
          getContract(
            ONE_DELTA_COMPOSER[chainId] ?? defaultAddress,
            COMPOSER,
            provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
            account
          )
        ]
      }
      case Lender.INIT: {
        const contract = getContract(
          initBrokerAddresses[chainId] ?? defaultAddress,
          BROKER_PROXY_ABI,
          provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
          account
        )
        return [contract, contract]
      }
      case Lender.COMPOUND_V2:
      case Lender.VENUS:
      case Lender.OVIX: {
        return [
          getContract(
            relevantAccount ?? defaultAddress,
            [],
            provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
            account
          ),
          getContract(
            relevantAccount ?? defaultAddress,
            [],
            provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
            account
          ),
        ]
      }
      case Lender.COMPOUND_V3: {
        return [
          getContract(
            addressesCompoundV3Core.comet[chainId]?.[baseAsset] ?? defaultAddress,
            COMET_ABI,
            provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
            account
          ),
          getContract(
            ONE_DELTA_COMPOSER[chainId] ?? defaultAddress,
            COMPOSER,
            provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
            account
          )]

      }
    }
  },
    [
      chainId,
      protocol,
      relevantAccount,
      baseAsset,
      isDirect,
      provider,
      account
    ])
}


// ********** COMET ***********

export function useGetCometRewardsContract(chainId: number, account?: string): CometRewards & Contract {
  const { provider } = useWeb3ReactWrapped()
  return getContract(
    addressesCompoundV3Core.cometRewards[chainId] ?? defaultAddress,
    COMET_REWARDS_ABI,
    provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
    account
  ) as CometRewards & Contract
}

// ********** AAVE Pool contract************

export function useGetAavePoolContractWithUserProvider(chainId: number, account?: string, lender = Lender.AAVE_V3): AAVEPoolV3 & Contract {
  const { provider } = useWeb3ReactWrapped()
  return getContract(
    getAaveTypePoolAddress(chainId, lender) ?? defaultAddress,
    AAVE_POOL_ABI, provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
    account
  ) as AAVEPoolV3 & Contract
}

// account is not optional
export function getDebtTokenContract(
  address?: string,
  library?: any,
  account?: string
): VariableDebtToken | undefined {
  if (!account || !address) return undefined
  return getContract(
    address,
    STABLE_DEBT_TOKEN, // the relevant functions are the same for stable and variable debt tokens
    library,
    account
  ) as VariableDebtToken
}


export function getTokenContractWithProvider(
  chainId?: number,
  address?: string,
): Erc20 | undefined {
  if (!chainId || !address) return undefined
  return getContract(
    address,
    ERC20_ABI, // the relevant functions are the same for stable and variable debt tokens
    RPC_PROVIDERS[chainId],
  ) as Erc20
}

export function getAaveOracleContract(chainId: number, account?: string): Contract {
  return getContract(
    chainId === SupportedChainId.MANTLE ? addressesLendleCore.AaveOracle[chainId] : addressesAaveCore.AaveOracle[chainId] ?? defaultAddress,
    chainId === SupportedChainId.MANTLE ? MANTLE_ORACLE_ABI : ORACLE_ABI,
    RPC_PROVIDERS[chainId as SupportedChainId],
    account
  )
}

export function getGhoOracleContract(chainId: number, account?: string): Contract {
  return getContract(
    addressesAaveCore.ghoOracle[chainId] ?? defaultAddress,
    GHO_ORACLE,
    RPC_PROVIDERS[chainId as SupportedChainId],
    account
  ) as GhoOracle
}

export function getCompoundLensContract(chainId: number, account?: string): Contract {
  return getContract(
    chainId === SupportedChainId.BSC ? venusAddresses.CompoundLens[chainId] :
      (ETHEREUM_CHAINS.includes(chainId)
        ? compoundAddresses.CompoundLens[chainId]
        : oVixAddresses.CompoundLens[chainId]) ?? defaultAddress,
    chainId === SupportedChainId.BSC ? VENUS_LENS_CLASSIC_ABI : ETHEREUM_CHAINS.includes(chainId) ? COMPOUND_LENS_CLASSIC_ABI : OVIX_LENS_CLASSIC_ABI,
    RPC_PROVIDERS[chainId as SupportedChainId],
    account
  )
}

// account is not optional
export function getCompoundComptrollerContract(chainId: number, library?: any, account?: string): Contract {
  return getContract(
    chainId === SupportedChainId.BSC
      ? venusAddresses.Unitroller[chainId] :
      chainId === SupportedChainId.GOERLI
        ? compoundAddresses.Unitroller[chainId]
        : POLYGON_CHAINS.includes(chainId)
          ? oVixAddresses.Unitroller[chainId]
          : defaultAddress,
    chainId === SupportedChainId.BSC
      ? VENUS_COMPTROLLER_ABI : COMPOUND_COMPTROLLER_ABI,
    library ?? RPC_PROVIDERS[chainId as SupportedChainId],
    account
  )
}

export function useGetDeltaBrokerContract(
  chainId: number,
  account?: string,
  lender?: Lender
): BrokerProxy & Contract {
  const { provider } = useWeb3ReactWrapped()
  return getContract(
    (lender === Lender.INIT ? initBrokerAddresses[chainId] : ONE_DELTA_COMPOSER[chainId]) ?? defaultAddress,
    lender === Lender.INIT ? BROKER_PROXY_ABI : COMPOSER,
    provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
    account
  ) as BrokerProxy & Contract
}

export function useGetDeltaComposerContract(
  chainId: number,
  account?: string,
  lender?: Lender
): Composer & BrokerProxy & Contract {
  const { provider } = useWeb3ReactWrapped()
  return getContract(
    (lender === Lender.INIT ? initBrokerAddresses[chainId] : ONE_DELTA_COMPOSER[chainId]) ?? defaultAddress,
    COMPOSER,
    provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
    account
  ) as Composer & BrokerProxy & Contract
}

// ********** LENDLE ************

export function getLendleIncentivesControllerContract(chainId: number, account?: string): IncentivesController & Contract {
  const { provider } = useWeb3ReactWrapped()
  return getContract(
    addressesLendleCore.IncentivesController[chainId] ?? defaultAddress,
    INCENTIVES_CONTROLLER_ABI,
    provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
    account
  ) as IncentivesController & Contract
}


// ********** MERIDIAN ************

export function getMeridianIncentivesControllerContract(chainId: number, account?: string): PullRewardsIncentivesController & Contract {
  const { provider } = useWeb3ReactWrapped()
  return getContract(
    addressesMeridianCore.IncentivesController[chainId] ?? defaultAddress,
    PULL_INCENTIVES_CONTROLLER_ABI,
    provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
    account
  ) as PullRewardsIncentivesController & Contract
}

// ********** AURELIUS ************

export function getAureliusRewarderContract(chainId: number, account?: string): Contract {
  const { provider } = useWeb3ReactWrapped()
  return getContract(
    getAaveTypeIncentivesControllerAddress(chainId, Lender.AURELIUS),
    AURELIUS_REWARDER_ABI,
    provider ?? RPC_PROVIDERS[chainId as SupportedChainId],
    account
  )
}