import { getLenderAssets } from 'constants/getAssets'
import { toErc20Asset } from 'types/1delta'
import { Lender } from 'types/lenderData/base'
import {
  AAVE_V2_SVG_STANDALONE,
  AAVE_V3_SVG_STANDALONE,
  COMPOUNDV2_SVG_STANDALONE,
  COMPOUNDV3_SVG,
  INIT_SVG,
  LENDLE_SVG,
  OVIX_SVG_STANDALONE,
  VENUS_SVG,
  MERIDIAN_SVG,
  AURELIUS_SVG,
} from 'constants/1delta'
import { OverlappingLogos } from 'components/OverlappingLogos'
import { Image, ImageProps } from '@chakra-ui/react'

export const getLenderUri = (protocol: Lender, chainId: number) => {
  switch (protocol) {
    case Lender.AAVE_V3:
      return AAVE_V3_SVG_STANDALONE
    case Lender.AAVE_V2:
      return AAVE_V2_SVG_STANDALONE
    case Lender.LENDLE:
      return LENDLE_SVG
    case Lender.INIT:
      return INIT_SVG
    case Lender.COMPOUND_V2:
      return COMPOUNDV2_SVG_STANDALONE
    case Lender.OVIX:
      return OVIX_SVG_STANDALONE
    case Lender.VENUS:
      return VENUS_SVG
    case Lender.MERIDIAN:
      return MERIDIAN_SVG
    case Lender.AURELIUS:
      return AURELIUS_SVG
    default: // Compound V3
      return COMPOUNDV3_SVG
  }
}

interface LenderLogoProps extends ImageProps {
  protocol: Lender
  chainId: number
  isLightMode: boolean
  size?: string
}

const LenderLogo: React.FC<LenderLogoProps> = ({ protocol, chainId, isLightMode, size, ...props }) => {
  const src = getLenderUri(protocol, chainId)
  return (
    <Image
      name={protocol}
      src={src}
      boxSize={'1.375rem'}
      filter={isLightMode && protocol === Lender.LENDLE ? 'invert(1)' : undefined}
      width={size}
      height={size}
      {...props}
    />
  )
}

interface SupportedLendersProps {
  chainId: number
  assetId: string
  isLightMode: boolean
  size?: string
}

export const SupportedLenders: React.FC<SupportedLendersProps> = ({ chainId, assetId, isLightMode, size }) => {
  const [aaveSupported, compound3Supported, lendleSupported, initSupported, meridianSupported, aureliusSupported] = [
    getLenderAssets(chainId, Lender.AAVE_V3).includes(toErc20Asset(assetId)),
    getLenderAssets(chainId, Lender.COMPOUND_V3).includes(toErc20Asset(assetId)),
    getLenderAssets(chainId, Lender.LENDLE).includes(toErc20Asset(assetId)),
    getLenderAssets(chainId, Lender.INIT).includes(toErc20Asset(assetId)),
    getLenderAssets(chainId, Lender.MERIDIAN).includes(toErc20Asset(assetId)),
    getLenderAssets(chainId, Lender.AURELIUS).includes(toErc20Asset(assetId)),
  ]

  return (
    <OverlappingLogos>
      {aaveSupported && (
        <LenderLogo protocol={Lender.AAVE_V3} chainId={chainId} isLightMode={isLightMode} size={size} />
      )}
      {compound3Supported && (
        <LenderLogo protocol={Lender.COMPOUND_V3} chainId={chainId} isLightMode={isLightMode} size={size} />
      )}
      {lendleSupported && (
        <LenderLogo protocol={Lender.LENDLE} chainId={chainId} isLightMode={isLightMode} size={size} />
      )}
      {initSupported &&
        <LenderLogo protocol={Lender.INIT} chainId={chainId} isLightMode={isLightMode} size={size} />
      }
      {meridianSupported &&
        <LenderLogo protocol={Lender.MERIDIAN} chainId={chainId} isLightMode={isLightMode} size={size} />
      }
      {aureliusSupported && (
        <LenderLogo protocol={Lender.AURELIUS} chainId={chainId} isLightMode={isLightMode} size={size} />
      )}
    </OverlappingLogos>
  )
}
