import { formatEther } from 'viem'
import { SupportedChainId } from 'constants/chains'
import { AprData, filterUserRewards, UserAprKey } from 'hooks/asset/useAssetData'
import { Lender } from 'types/lenderData/base'
import { formatNumberToPercent, roundNumber } from 'utils/1delta/generalFormatters'

const BLOCK_TIMES: { [chainId: number]: number } = {
  5: 12,
  1: 12,
  56: 3
}

export const positivePart = (n: number) => n < 0 ? 0 : n

export function nanTo(possiblyNaN: number, replacement = Infinity) {
  return isNaN(possiblyNaN) ? replacement : possiblyNaN
}

export enum YieldType {
  Deposit,
  Borrow,
  Total,
}

export function getExtractTotalYield(aprData: AprData, yieldType = YieldType.Deposit) {
  switch (yieldType) {
    case YieldType.Deposit:
      return {
        apr: aprData.depositApr, staking: aprData.stakingDepositApr,
        reward: filterUserRewards(aprData.rewards, UserAprKey.apr)
      }
    case YieldType.Borrow:
      return {
        apr: -aprData.borrowApr, staking: -aprData.stakingBorrowApr,
        reward: filterUserRewards(aprData.rewards, UserAprKey.borrowApr)
      }
    case YieldType.Total:
      return {
        apr: aprData.apr, staking: aprData.stakingApr,
        reward: filterUserRewards(aprData.rewards, UserAprKey.apr)
      }
  }
}

export function getTotalYield(organic: number, rewards = 0, staking = 0) {
  return formatNumberToPercent((organic ?? 0) + (rewards ?? 0) + (staking ?? 0))
}

export function getSafeSum(organic: number, rewards = 0, staking = 0) {
  return ((organic ?? 0) + (rewards ?? 0) + (staking ?? 0))
}


/**
 * Formats a price decimal number or number string
 * Assumes positive prices, ceils display at $1M
 * @param price price data
 * @param precision decimals to show
 * @returns price formatted as string
 */
export const formatPriceString = (price?: string | number, precision = 2) => {
  const numberPrice = Number(price)
  if (numberPrice && !isNaN(numberPrice)) {
    if (numberPrice > 1e6) return '>$1M' // ceil for abbreviation is 1M
    return formatDecimalWithPrecision(numberPrice, precision)
  }
  return '-'
}

export const formatAbbreviatedPrice = (amount?: number) => {
  if (amount == null || isNaN(amount)) return '-'
  const n = Number(amount)
  if (n < 1e3) return formateDecimal(n)
  if (n >= 1e3 && n < 1e6) return formateDecimal(n / 1e3) + 'K'
  if (n >= 1e6 && n < 1e9) return formateDecimal(n / 1e6) + 'M'
  if (n >= 1e9 && n < 1e12) return formateDecimal(n / 1e9) + 'B'
  if (n >= 1e12 && n < 1e15) return formateDecimal(n / 1e12) + 'T'
  if (n >= 1e15 && n < 1e18) return formateDecimal(n / 1e15) + 'P'
  if (n !== Infinity) return formateDecimal(n / 1e18) + 'E'
  return '-'
}


export const formatAbbreviatedGeneralUSDAmount = (amount?: number) => {
  if (amount == undefined || isNaN(amount)) return '-'
  if (!amount) return '0.0'
  if (Math.abs(amount) <= 0.01) return formatSmallUSDValueRounded(amount)
  const sign = Number(amount) >= 0 ? '+' : '-'
  const n = Math.abs(Number(amount))
  if (n < 1e5) return sign + formateDecimal(n)
  if (n >= 1e5 && n < 1e6) return sign + formateDecimal(n / 1e3) + 'K'
  if (n >= 1e6 && n < 1e9) return sign + formateDecimal(n / 1e6) + 'M'
  if (n >= 1e9 && n < 1e12) return sign + formateDecimal(n / 1e9) + 'B'
  if (n >= 1e12 && n < 1e15) return sign + formateDecimal(n / 1e12) + 'T'
  if (n >= 1e15 && n < 1e18) return sign + formateDecimal(n / 1e15) + 'P'
  if (n !== Infinity) return sign + formateDecimal(n / 1e18) + 'E'
  return '-'
}

export const formatSmallValueNoFallback = (amount?: number, mobile = false) => {
  if (!amount) return '-'
  return formatSmallValue(amount, mobile)
}


export const formatSmallValue = (amount?: number | string, mobile = false) => {
  if (amount == undefined) return '-'
  if (!amount) return '0.0'
  const n = Number(amount)
  if (n < 0.0001) return '<0.0001'
  return mobile ? amount.toLocaleString('en-EN', {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  }) : amount.toLocaleString()
}

export const formatSmallGeneralValue = (amount?: number, mobile = false) => {
  if (amount == undefined) return '-'
  if (!amount) return '0.0'
  const n = Math.abs(Number(amount))
  if (n < 0.00001) return '<0.00001'
  return amount.toLocaleString('en-EN', {
    minimumFractionDigits: 6,
    maximumFractionDigits: 6,
  })
}

export const formatSmallUSDValueNoFallback = (amount?: number) => {
  if (!amount) return '-'
  return formatSmallUSDValue(amount)
}

export const formatSmallUSDValue = (amount?: number) => {
  if (amount == undefined) return '-'
  if (!amount) return '0.0'
  const n = Number(amount)
  if (n < 0.001) return '< $0.001'
  if (n < 0.01) return '< $0.01'
  return `$${(roundNumber(amount, 2)).toLocaleString()}`
}

export const formatSmallUSDValueRounded = (amount?: number, decimals = 2) => {
  if (amount == undefined) return '-'
  if (!amount) return '0.0'
  const isNeg = amount < 0
  const n = Math.abs(amount)
  if (!isNeg) {
    if (n < 0.001) return '< $0.001'
    if (n < 0.01) return '< $0.01'
    return `$${n.toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })}`
  } else {
    if (n < 0.001) return '> -$0.001'
    if (n < 0.01) return '> -$0.01'
    return `-$${n.toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })}`
  }
}


export const formatSmallGeneralUSDValue = (amount?: number) => {
  if (amount == undefined) return '-'
  if (!amount) return '$0.0'
  const n = Math.abs(amount)
  const isPos = amount < 0
  if (n < 0.0001) return isPos ? '~$0' : '~$0.'
  if (n < 0.001) return isPos ? '~$0.001' : '~-$0.001'
  if (n < 0.01) return isPos ? '~$0.01' : '~-$0.01'
  return `${isPos ? '' : '-'}$${(roundNumber(n, 2)).toLocaleString()}`
}


export const formateDecimal = (amount: number) =>
  amount.toLocaleString('en-EN', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

const formatDecimalWithPrecision = (amount: number, precision: number) =>
  amount.toLocaleString('en-EN', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })

const ONE_E_7 = BigInt(1e7)


export const formatAaveYield = (n: string): string => {
  return (roundNumber(Number(formatEther(BigInt(n) / ONE_E_7)), 2)).toLocaleString()
}

export const formatAaveYieldToNumber = (n?: string | bigint): number => {
  if (!n) return 0
  return Number(formatEther(BigInt(n) / BigInt(1e7)))
}


export const formatAaveToNumber = (n: string): number => {
  return roundNumber(Number(formatEther(BigInt(n) / ONE_E_7)), 2)
}

export const formatCompoundYield = (n: string): string => {
  return (roundNumber(Number(formatEther(BigInt(n) / ONE_E_7)), 2)).toLocaleString()
}

export const convertRatePerBlockToRatePerYear = (n: string, chainId: number): string => {
  return (
    roundNumber(
      Number(
        formatEther(
          BigInt(n) *
          (3600n * 24n * 365n) /
          BigInt(BLOCK_TIMES[chainId] ?? 1)
        )
      )
      , 2
    )
  ).toLocaleString()
}

export enum TimeScale {
  BLOCK,
  MS,
}

export const calculateRate = (n: string, chainId: number, scale = TimeScale.BLOCK): string => {
  const rate = Number(formatEther(BigInt(n)))
  if (scale === TimeScale.BLOCK)
    return ((Math.pow((rate * 60 * 60 * 24) / (BLOCK_TIMES[chainId] ?? 1) + 1, 365) - 1) * 100).toLocaleString()

  return ((Math.pow(rate * 60 * 60 * 24 + 1, 365) - 1) * 100).toLocaleString()
}


export const calculateRateToNumber = (n: string, chainId: number, scale = TimeScale.BLOCK): number => {
  const rate = Number(formatEther(BigInt(n)))
  if (scale === TimeScale.BLOCK)
    return (Math.pow((rate * 60 * 60 * 24) / (BLOCK_TIMES[chainId] ?? 1) + 1, 365) - 1) * 100

  return (Math.pow(rate * 60 * 60 * 24 + 1, 365) - 1) * 100
}

const formatNumber = (amount: number) =>
  amount.toLocaleString('en-EN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const formatAbbreviatedNumberNoFallback = (amount?: number) => {
  if (amount == null || isNaN(amount)) return '-'
  return formatAbbreviatedNumber(amount)
}

export const formatAbbreviatedNumber = (amount?: number | string) => {
  if (amount == undefined) return '-'
  if (!amount) return '0'
  const n = Number(amount)
  if (n === 0) return '0.0'
  if (n < 0.001) return formatSmallGeneralValue(n)
  if (n < 1) return amount.toLocaleString('en-EN', {
    minimumFractionDigits: 6,
    maximumFractionDigits: 6,
  })
  if (n < 1e3) return formatNumber(n)
  if (n >= 1e3 && n < 1e6) return formatNumber(n / 1e3) + 'K'
  if (n >= 1e6 && n < 1e9) return formatNumber(n / 1e6) + 'M'
  if (n >= 1e9 && n < 1e12) return formatNumber(n / 1e9) + 'B'
  if (n >= 1e12 && n < 1e15) return formatNumber(n / 1e12) + 'T'
  if (n >= 1e15 && n < 1e18) return formatNumber(n / 1e15) + 'P'
  if (n !== Infinity) return formatNumber(n / 1e18) + 'E'
  if (n === Infinity) return <>&infin;</>
  return '0'
}


export const formatAbbreviatedDollarNumber = (amount?: number) => {
  if (amount == undefined) return '-'
  if (!amount) return '$0'
  const n = Math.abs(amount)
  if (amount > 0) {
    if (n < 1e3) return '$' + formatNumber(n)
    if (n >= 1e3 && n < 1e6) return '$' + formatNumber(n / 1e3) + 'K'
    if (n >= 1e6 && n < 1e9) return '$' + formatNumber(n / 1e6) + 'M'
    if (n >= 1e9 && n < 1e12) return '$' + formatNumber(n / 1e9) + 'B'
    if (n >= 1e12 && n < 1e15) return '$' + formatNumber(n / 1e12) + 'T'
    if (n >= 1e15 && n < 1e18) return '$' + formatNumber(n / 1e15) + 'P'
    if (n !== Infinity) return '$' + formatNumber(n / 1e18) + 'E'
    if (n === Infinity) return <>&infin;</>
    return '0.0'
  } else {
    if (n < 1e3) return '-$' + formatNumber(n)
    if (n >= 1e3 && n < 1e6) return '-$' + formatNumber(n / 1e3) + 'K'
    if (n >= 1e6 && n < 1e9) return '-$' + formatNumber(n / 1e6) + 'M'
    if (n >= 1e9 && n < 1e12) return '-$' + formatNumber(n / 1e9) + 'B'
    if (n >= 1e12 && n < 1e15) return '-$' + formatNumber(n / 1e12) + 'T'
    if (n >= 1e15 && n < 1e18) return '-$' + formatNumber(n / 1e15) + 'P'
    if (n !== Infinity) return '-$' + formatNumber(n / 1e18) + 'E'
    if (n === Infinity) return <>-&infin;</>
    return '0.0'
  }
}


export const formatAbbreviatedGeneralNumber = (amount?: number) => {
  if (amount == undefined) return '-'
  if (!amount) return '0.0'
  const sign = Number(amount) >= 0 ? '+' : '-'
  const n = Math.abs(Number(amount))
  if (n < 1e3) return sign + formatNumber(n)
  if (n >= 1e3 && n < 1e6) return sign + formatNumber(n / 1e3) + 'K'
  if (n >= 1e6 && n < 1e9) return sign + formatNumber(n / 1e6) + 'M'
  if (n >= 1e9 && n < 1e12) return sign + formatNumber(n / 1e9) + 'B'
  if (n >= 1e12 && n < 1e15) return sign + formatNumber(n / 1e12) + 'T'
  if (n >= 1e15 && n < 1e18) return sign + formatNumber(n / 1e15) + 'P'
  if (n !== Infinity) return sign + formatNumber(n / 1e18) + 'E'
  if (n === Infinity) return sign + <>&infin;</>
  return '0.0'
}


interface ObjectWithApr {
  apr: number
}

export function compareApr(a: ObjectWithApr, b: ObjectWithApr) {
  if (a.apr < b.apr) {
    return -1
  }
  if (a.apr > b.apr) {
    return 1
  }
  return 0
}


export const formatUnix = (unix: number): string => {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(unix * 1000);
  // Minutes part from the timestamp
  const minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  const seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  return date.getHours() + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

}

export const safeFormatUnix = (unix?: number): string | undefined => {
  if (!unix) return undefined
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(unix * 1000);
  // Minutes part from the timestamp
  const minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  const seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  return date.getHours() + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

}



export const formatSmallValueWithDefault = (amount?: number) => {
  if (amount == undefined) return '-'
  const n = Number(amount)
  if (n === 0) return '0.0'
  if (n < 0.0001) return '~0'
  if (n < 0.001) return '~0.001'
  if (n < 0.005) return '~0.005'
  return amount.toLocaleString('en-EN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}


export const formatSmallUSDValueWithDefault = (amount?: number) => {
  if (amount == undefined) return '-'
  if (amount === 0) return '$0.0'
  const n = Number(amount)
  if (n < 0.001) return '< $0.001'
  if (n < 0.01) return '< $0.01'
  return `$${(roundNumber(amount, 2)).toLocaleString()}`
}


export const calculateRateForCompound = (n: string, chainId: number, lender = Lender.COMPOUND_V3): number => {
  let scale = TimeScale.MS
  if (lender === Lender.COMPOUND_V3 || Lender.COMPOUND_V2) {
    scale = TimeScale.BLOCK
  } else if (chainId === SupportedChainId.GOERLI || chainId === SupportedChainId.BSC) {
    scale = TimeScale.BLOCK
  }
  const rate = Number(formatEther(BigInt(n)))
  if (scale === TimeScale.BLOCK)
    return (Math.pow((rate * 60 * 60 * 24) / (BLOCK_TIMES[chainId] ?? 1) + 1, 365) - 1) * 100

  return (Math.pow(rate * 60 * 60 * 24 + 1, 365) - 1) * 100
}
