import { createReducer } from '@reduxjs/toolkit'
import {
  resetInitUserState,
  resetState,
  selectPosId,
} from './actions'
import {
  fetchInitPublicData,
} from './fetchPublicData'
import { InitState } from 'types/lenderData/init'
import { fetchInitUserData } from './fetchUserData'
import { STATE_CHAINIDS } from 'constants/chains'
import { safeDivide } from 'utils/1delta/generalFormatters'

const emptyData = {
  publicLoaded: false,
  lenderData: {},
  userConfigs: {},
  userData: {},
  balanceData: {},
  aprData: {},
  selectedConfig: {
    id: '',
    mode: 0
  }
}

export const initialState: InitState = Object.assign(
  {}, ...STATE_CHAINIDS.map(cId => { return { [cId]: emptyData } })
)

export default createReducer<InitState>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    .addCase(resetInitUserState, (state, action) => {
      const chainId = action.payload.chainId
      if (state[chainId])
        state[chainId].userData = {}
    })
    .addCase(selectPosId, (state, action) => {
      const { posId, chainId } = action.payload
      state[chainId].selectedConfig.id = posId
      state[chainId].selectedConfig.mode = state[chainId].userConfigs?.[posId]?.selectedMode

    })
    // new reserve data-fetch using aave data provider
    .addCase(fetchInitPublicData.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      const chainId = action.payload.chainId
      if (assetKeys.length === 0) return; // prevents setting load flags
      if (!state[chainId]) state[chainId] = emptyData
      // assign public data
      for (let i = 0; i < assetKeys.length; i++) {
        state[chainId].lenderData[assetKeys[i]] = {
          ...state[chainId].lenderData[assetKeys[i]],
          ...action.payload.data[assetKeys[i]],
        }
      }
      state[chainId].publicLoaded = true
    })
    .addCase(fetchInitPublicData.pending, (state) => {
      //
    })
    // user data from provider
    .addCase(fetchInitUserData.fulfilled, (state, action) => {
      const positionKeys = Object.keys(action.payload.tokensData)
      let assetKeys = action.payload.assets
      const { chainId, account } = action.payload
      if (!state[chainId].lenderData) return;
      if (!state[chainId]) state[chainId] = emptyData
      let userConfigs: any = {}
      // main user data
      for (let j = 0; j < positionKeys.length; j++) {
        const posId = positionKeys[j]
        const position = action.payload.tokensData[posId]
        const { mode } = position
        userConfigs[posId] = {
          id: posId,
          selectedMode: mode,
          isAllowed: position.isAllowed,
          isApprovedForAll: position.isApprovedForAll
        }
        // organic yields
        let depositInterest = 0
        let borrowInterest = 0
        // rewards
        let rewardDepositAccrual = 0
        let rewardBorrowAccrual = 0
        // staking
        let stakingDepositAccrual = 0
        let stakingBorrowAccrual = 0
        // amountrs
        let deposits = 0
        let debt = 0
        let collateral = 0
        let borrowDiscountedCollateral = 0
        let adjustedDebt = 0
        for (let i = 0; i < assetKeys.length; i++) {
          const asset = assetKeys[i]
          const { depositsUSD, debtUSD } = action.payload.tokensData[posId].positions[asset]
          if (!state[chainId].lenderData[asset]) continue;
          const { depositRate, stakingYield, variableBorrowRate, rewards } = state[chainId].lenderData[asset]
          // amounts
          deposits += depositsUSD
          debt += debtUSD
          // rewards
          Object.entries(rewards ?? {}).map(([key, rewardData]) => {
            rewardDepositAccrual += rewardData.depositRate * depositsUSD
            rewardBorrowAccrual += rewardData.variableBorrowRate * debtUSD + rewardData.stableBorrowRate * debtUSD
          })
          // staking
          stakingDepositAccrual += (stakingYield ?? 0) * depositsUSD
          stakingBorrowAccrual += (stakingYield ?? 0) * debtUSD

          const config = state[chainId].lenderData[asset].config[mode]

          // risk adjusted
          collateral += (config?.collateralFactor ?? 1) * depositsUSD
          borrowDiscountedCollateral += (config?.borrowCollateralFactor ?? 1) * depositsUSD
          adjustedDebt += (config?.borrowFactor ?? 1) * debtUSD

          // IRs
          depositInterest += depositRate * depositsUSD
          borrowInterest += debtUSD * variableBorrowRate
          if (!state[chainId].userData[posId]) state[chainId].userData[posId] = {}
          // add data to the state
          state[chainId].userData[posId][asset] = action.payload.tokensData[posId].positions[asset]

        }
        const nav = deposits - debt
        // aggregated balance data
        state[chainId].balanceData[posId] = {
          borrowDiscountedCollateral,
          collateral,
          deposits,
          debt,
          adjustedDebt,
          nav
        }
        if (!state[chainId].aprData) state[chainId].aprData = {}
        // aggregated apr data
        state[chainId].aprData[posId] = {
          apr: safeDivide(depositInterest - borrowInterest, nav),
          borrowApr: safeDivide(borrowInterest, debt),
          depositApr: safeDivide(depositInterest, deposits),
          rewardApr: safeDivide(rewardDepositAccrual + rewardBorrowAccrual, nav),
          rewardDepositApr: safeDivide(rewardDepositAccrual, deposits),
          rewardBorrowApr: safeDivide(rewardBorrowAccrual, debt),
          stakingApr: safeDivide(stakingDepositAccrual + stakingBorrowAccrual, nav),
          stakingDepositApr: safeDivide(stakingDepositAccrual, deposits),
          stakingBorrowApr: safeDivide(stakingBorrowAccrual, debt),
        }
      }
      state[chainId].userConfigs = userConfigs
      if (!state[chainId].selectedConfig.id && positionKeys.length > 0) {
        const posId = positionKeys[0]
        state[chainId].selectedConfig.id = posId
        state[chainId].selectedConfig.mode = action.payload.tokensData[posId].mode
      }
    })
    .addCase(fetchInitUserData.pending, (state) => {
      //
    })
)