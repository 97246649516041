import { getFallbackViemProvider, getViemProvider } from 'hooks/providers/evm/useViemClient'
import { Address } from 'viem'

export type MultiCallResponse<T> = T | null

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

interface ViemMulticallresult {
  result: any[],
  status: string
}

export const multicallViem = async (chainId: number, abi: any[], calls: Call[], providerId = 0, retry = true, fallback = false, requireSuccess = false): Promise<any[]> => {
  try {
    const provider = fallback ? getFallbackViemProvider({ chainId, id: providerId }) : getViemProvider({ chainId, id: providerId })

    const returnData = await provider.multicall({
      allowFailure: !requireSuccess,
      contracts: calls.map(({ address, name, params }) => ({
        abi: abi as any,
        address: address as Address,
        functionName: name,
        args: params,
      })),
    })

    const res = (returnData as any[]).map(
      ({ result, status }: ViemMulticallresult) => status !== 'success' ? '0x' : result
    )

    return res
  } catch (error: any) {
    // console.log("Error with multicall", error)
    if (fallback || !retry) // if we are already in the fallback, we throw an error
      throw new Error(error)
    else
      return multicallViem(chainId, abi, calls, providerId, false, true, requireSuccess)
  }
}

export function fallbackOnResult(result: string, fallbackValue = '0') {
  return result === '0x' ? fallbackValue : result
}