import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { AddressDictionary } from './addresses'

export const addressesInitCore: AddressDictionary = {
  InitOracle: {
    [SupportedChainId.MANTLE]: '0x4E195A32b2f6eBa9c4565bA49bef34F23c2C0350'
  },
  Core: {
    [SupportedChainId.MANTLE]: '0x972BcB0284cca0152527c4f70f8F689852bCAFc5'
  },
  Config: {
    [SupportedChainId.MANTLE]: '0x007F91636E0f986068Ef27c950FA18734BA553Ac'
  },
  PosManager: {
    [SupportedChainId.MANTLE]: '0x0e7401707CD08c03CDb53DAEF3295DDFb68BBa92'
  },
  Lens: {
    [SupportedChainId.MANTLE]: '0x7d2b278b8ef87bEb83AeC01243ff2Fed57456042'
  },
  PublicLens: {
    [SupportedChainId.MANTLE]: '0xDf75fF0452eDf4897B051A54b406eB384503Ac39'
  },
}