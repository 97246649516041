import { Currency } from '@1delta/base-sdk'
import { HStack, VStack, Text, TextProps, StackProps } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { AssetStatusIndicator } from './assetStatusIndicator'

interface CurrencyDetailsProps extends StackProps {
  currency: Currency
  symbolProps?: TextProps
  nameProps?: TextProps
  textStackProps?: StackProps
  showFlags?: boolean
  isMedium?: boolean
  shortName?: boolean
  customAdded?: boolean
  hideName?: boolean
  logoSize?: string
}

export const CurrencyDetails: React.FC<CurrencyDetailsProps> = ({
  currency,
  symbolProps = {},
  nameProps = {},
  textStackProps = {},
  showFlags,
  isMedium = false,
  shortName,
  customAdded = false,
  hideName = false,
  logoSize,
  ...props
}) => {
  return (
    <HStack {...props}>
      <CurrencyLogo currency={currency} size={logoSize} />
      <VStack alignItems={'flex-start'} gap={'0.25rem'} {...textStackProps}>
        <HStack>
          <Text
            style={getTypography('Typography/Body-Labels/Normal/Body')}
            color={validateColor('Text/Lables/Label-text-default')}
            lineHeight={'1 !important'}
            {...symbolProps}
          >
            {currency.symbol} {customAdded && '(Custom)'}
          </Text>
          {showFlags && <AssetStatusIndicator currency={currency} />}
        </HStack>
        {!hideName && !isMedium && currency?.name ? (
          <Text
            style={
              !shortName
                ? getTypography('Typography/Small/Normal/Small 2')
                : {
                    ...getTypography('Typography/Small/Normal/Small 2'),
                    display: 'block',
                    textOverflow: 'ellipsis',
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    maxWidth: '100px',
                  }
            }
            color={validateColor('Text/Lables/Label-text-disabled')}
            lineHeight={'1 !important'}
            {...nameProps}
          >
            {currency?.name}
          </Text>
        ) : null}
      </VStack>
    </HStack>
  )
}
