import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { AddressDictionary } from './addresses'

export const addressesAureliusCore: AddressDictionary = {
  AaveOracle: {
    [SupportedChainId.MANTLE]: '0x6F1B94b8260af71FEc42EC61FC354f43fb3D6293'
  },
  PoolProxy: {
    [SupportedChainId.MANTLE]: '0x7c9C6F5BEd9Cfe5B9070C7D3322CF39eAD2F9492'
  },
  PoolDataProvider: {
    [SupportedChainId.MANTLE]: '0xedB4f24e4b74a6B1e20e2EAf70806EAC19E1FA54'
  },
  IncentivesController: {
    [SupportedChainId.MANTLE]: '0xF57e3f93A2097Ab2e3BA767cA0884E0e8D32Bb9b'
  },
  MultiFeeDistribution: {
    [SupportedChainId.MANTLE]: '0x'
  },
}

export const addressesAureliusATokens: AddressDictionary = {
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: '0x893da3225a2fcf13cca674d1a1bb5a2ea1f3dd14'
  },
  [SupportedAssets.USDC]: {
    [SupportedChainId.MANTLE]: '0x833b5c0379a597351c6cd3efe246534bf3ae5f9f'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: '0xc3b515bca486520483ef182c3128f72ce270c069'
  },
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: '0x067ddc903148968d49abc3144fd7619820f16949'
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: '0xf91798762cc61971df6df0e15f0904e174387477'
  },
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: '0xbb406187c01cc1c9eaf9d4b5c924b7fa37aecefd'
  },
  [SupportedAssets.USDE]: {
    [SupportedChainId.MANTLE]: '0x7bdb0095429f8eff1efb718aabc912b2489ba5b3'
  },
  [SupportedAssets.USDY]: {
    [SupportedChainId.MANTLE]: '0xfdd2ebc184b4ff6df14562715452e970c82fe49a'
  },
  [SupportedAssets.FBTC]: {
    [SupportedChainId.MANTLE]: '0x491F8FBC6b9a5db31c959a702aB6A0dCBEA73a48'
  },
}

export const addressesAureliusVTokens: AddressDictionary = {
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: '0xc799fe29b67599010a55ec14a8031af2a2521470'
  },
  [SupportedAssets.USDC]: {
    [SupportedChainId.MANTLE]: '0xaa9c890ca3e6b163487de3c11847b50e48230b45'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: '0x45ccce9bc8e883ef7805ea73b88d5d528c7cec55'
  },
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: '0x4c3c0650ddcb767d71c91fa89ee9e5a2cd335834'
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: '0xd632fd1d737c6db356d747d09642bef8ae453f4d'
  },
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: '0x00dfd5f920ccf08eb0581d605bab413d289c21b4'
  },
  [SupportedAssets.USDE]: {
    [SupportedChainId.MANTLE]: '0xcbe019c9c44954d388602a99a45a1d7da61321cf'
  },
  [SupportedAssets.USDY]: {
    [SupportedChainId.MANTLE]: '0x2d55f5558aea4c25fcc1ff78b10265755aff3856'
  },
  [SupportedAssets.FBTC]: {
    [SupportedChainId.MANTLE]: '0xd2ea6612f6c7c11626F7D5D801D08B53BCe52511'
  },
}

export const addressesAureliusSTokens: AddressDictionary = {
  [SupportedAssets.USDT]: {
    [SupportedChainId.MANTLE]: '0x61627c3e37a4e57a4edb5cd52ce8221d9c5bda3d'
  },
  [SupportedAssets.USDC]: {
    [SupportedChainId.MANTLE]: '0xb41cf1eeadfd17fbc0086e9e856f1ac5460064d2'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.MANTLE]: '0xfbace7bf40dd1b9158236a23e96c11ebd03a2d42'
  },
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: '0x6110868e963f8badf4d79bc79c8ac1e13cd59735'
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MANTLE]: '0xbc9b223d335c624f55c8b3a70f883ffefb890a0e'
  },
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: '0x2d422c5ead5fa3c26aec97d070343353e2086a1d'
  },
  [SupportedAssets.USDE]: {
    [SupportedChainId.MANTLE]: '0x7f9c39386be3f6a8c1753b5fbe8c96f7eaef88e1'
  },
  [SupportedAssets.USDY]: {
    [SupportedChainId.MANTLE]: '0xe0ae10fb6cb1a366c56204afe1ae2a94a0ed9a11'
  },
  [SupportedAssets.FBTC]: {
    [SupportedChainId.MANTLE]: '0x88140812eE16B48447eAF7BA9A5649D1F9A2d949'
  },
}
