import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { getAddressesForChainIdFromAssetDict } from 'hooks/1delta/addresses'
import { Call, multicallViem } from 'utils/multicall'
import AAVE_POOL_DATA_PROVIDER_ABI from 'abis/lendle/ProtocolDataProvider.json'
import UNI_V2_PAIR_ABI from 'abis/uniswap/uniswap-v2-pair.json'
import { BPS, TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { getAaveV2TokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { LENDER_MODE_NO_MODE, Lender, RewardsMap } from 'types/lenderData/base'
import { formatAaveRawApyToApr } from 'utils/1delta/generalFormatters'
import { addressesAaveV2ATokens } from 'hooks/1delta/addressesAaveV2'
import { getLenderAssets } from 'constants/getAssets'
import { getAaveTypePoolDataProviderAddress } from 'hooks/1delta'

export enum AaveV2TypeGetReserveDataIndexes {
  availableLiquidity = 0,
  totalStableDebt,
  totalVariableDebt,
  liquidityRate,
  variableBorrowRate,
  stableBorrowRate,
  averageStableBorrowRate,
  liquidityIndex,
  variableBorrowIndex,
  lastUpdateTimestamp,
}

export enum AaveV2TypeGetReserveConfigurationData {
  decimals = 0,
  ltv,
  liquidationThreshold,
  liquidationBonus,
  reserveFactor,
  usageAsCollateralEnabled,
  borrowingEnabled,
  stableBorrowRateEnabled,
  isActive,
  isFrozen,
}

interface AaveV2PoolReserveResponse {
  data: {
    [tokenSymbol: string]: {
      // token amounts
      totalDeposits: number;
      totalDebtStable: number;
      totalDebt: number;
      // USD amounts
      totalDepositsUSD: number;
      totalDebtStableUSD: number;
      totalDebtUSD: number;

      // reserve market data
      depositRate: number
      variableBorrowRate: number
      stableBorrowRate: number
      lastUpdateTimestamp?: number
      stakingYield: number

      // rewards
      rewards?: RewardsMap

      // reserve config
      decimals?: number
      usageAsCollateralEnabled?: boolean
      hasStable?: boolean

      // frozen
      isActive?: boolean
      isFrozen?: boolean
    }
  }
  config: {
    [tokenSymbol: string]: {
      decimals: number;

      config: {
        [0]: {
          modeId: 0,
          // collateral factors
          borrowCollateralFactor: number,
          collateralFactor: number,
          borrowFactor: number
        }
      }

      // flags
      collateralActive: boolean;
      borrowingEnabled: boolean;
      hasStable: boolean;
      isActive: boolean;
      isFrozen: boolean;
    }
  }
  chainId: number
}

interface AaveV2ReservesQueryParams {
  chainId: number
  prices: { [asset: string]: number }
  stakingYields: { [asset: string]: number; }
}


export const fetchAaveV2PublicData: AsyncThunk<AaveV2PoolReserveResponse, AaveV2ReservesQueryParams, any> =
  createAsyncThunk<AaveV2PoolReserveResponse, AaveV2ReservesQueryParams>(
    'aave-v2/fetchAaveV2PublicData',
    async ({ chainId, prices, stakingYields }) => {
      const providerAddress = getAaveTypePoolDataProviderAddress(chainId, Lender.AAVE_V2)
      const tokenDict = getAaveV2TokenAddresses(chainId)
      const assetsToQuery = getLenderAssets(chainId, Lender.AAVE_V2)
      const assets = assetsToQuery.map((a) => tokenDict[a])
      const names = Object.keys(tokenDict)
      const calls: Call[] = assets.map((tk) => {
        return {
          address: providerAddress,
          name: 'getReserveData',
          params: [tk],
        }
      })
      const aTokenDict = getAddressesForChainIdFromAssetDict(addressesAaveV2ATokens, chainId, Lender.AAVE_V2)
      const aTokenNames = Object.keys(aTokenDict)
      const callsConfig: Call[] = assets.map((tk) => {
        return {
          address: providerAddress,
          name: 'getReserveConfigurationData',
          params: [tk],
        }
      })

      let multicallResult: any[]
      try {
        if (calls.length > 0)
          multicallResult = await multicallViem(
            chainId,
            [...AAVE_POOL_DATA_PROVIDER_ABI, ...UNI_V2_PAIR_ABI],
            [
              ...calls, ...callsConfig,
            ],
            1 // secondary
          )
        else
          multicallResult = []
      } catch (err) {
        console.log('error', err)
        multicallResult = []
      }
      // slice the results
      const multicallResultReserves = multicallResult.slice(0, calls.length)
      const resultReserves: any = Object.assign(
        {},
        ...multicallResultReserves.map((entry: any, index) => {
          const asset = names[index]
          const decimals = TOKEN_META[asset]?.decimals ?? 18
          const totalStableDebt = parseRawAmount(entry?.[AaveV2TypeGetReserveDataIndexes.totalStableDebt]?.toString(), decimals)
          const totalVariableDebt = parseRawAmount(entry?.[AaveV2TypeGetReserveDataIndexes.totalVariableDebt]?.toString(), decimals)
          const liquidity = parseRawAmount(entry?.[AaveV2TypeGetReserveDataIndexes.availableLiquidity]?.toString(), decimals)
          const totalAToken = liquidity + totalStableDebt + totalVariableDebt
          const price = prices[asset] ?? 1

          return {
            [asset]: {
              // token amounts
              totalDeposits: totalAToken,
              totalDebtStable: totalStableDebt,
              totalDebt: totalVariableDebt,
              totalLiquidity: liquidity,
              // USD amountss
              totalDepositsUSD: totalAToken * price,
              totalDebtStableUSD: totalStableDebt * price,
              totalDebtUSD: totalVariableDebt * price,
              totalLiquidityUSD: liquidity * price,
              // rates
              depositRate: formatAaveRawApyToApr(entry?.[AaveV2TypeGetReserveDataIndexes.liquidityRate]?.toString()),
              variableBorrowRate: formatAaveRawApyToApr(entry?.[AaveV2TypeGetReserveDataIndexes.variableBorrowRate]?.toString()),
              stableBorrowRate: formatAaveRawApyToApr(entry?.[AaveV2TypeGetReserveDataIndexes.stableBorrowRate]?.toString()),
              stakingYield: stakingYields[asset] ?? 0,

              // rewards
              rewards: {},
            },
          }
        })
      )

      const multicallResultConfig = multicallResult.slice(calls.length, multicallResult.length)
      const resultConfig: any = Object.assign(
        {},
        ...multicallResultConfig.map((entry: any, index) => {
          return {
            [aTokenNames[index]]: {
              decimals: Number(entry?.[AaveV2TypeGetReserveConfigurationData.decimals]),
              config: {
                [LENDER_MODE_NO_MODE]: {
                  modeId: LENDER_MODE_NO_MODE,
                  // collateral factors
                  borrowCollateralFactor: Number(entry?.[AaveV2TypeGetReserveConfigurationData.ltv].toString()) / BPS,
                  collateralFactor: Number(entry?.[AaveV2TypeGetReserveConfigurationData.liquidationThreshold].toString()) / BPS,
                  borrowFactor: 1
                }
              },
              liquidationBonus: Number(entry?.[AaveV2TypeGetReserveConfigurationData.liquidationBonus].toString()) / BPS,

              // flags
              collateralActive: entry?.[AaveV2TypeGetReserveConfigurationData.usageAsCollateralEnabled],
              borrowingEnabled: entry?.[AaveV2TypeGetReserveConfigurationData.borrowingEnabled],
              hasStable: entry?.[AaveV2TypeGetReserveConfigurationData.stableBorrowRateEnabled],
              isActive: entry?.[AaveV2TypeGetReserveConfigurationData.isActive],
              isFrozen: entry?.[AaveV2TypeGetReserveConfigurationData.isFrozen],

            },
          }
        })
      )

      return {
        data: resultReserves,
        config: resultConfig,
        chainId,
      }
    }
  )
