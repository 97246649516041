import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { getAddressesForChainIdFromAssetDict } from 'hooks/1delta/addresses'
import { multicallViem } from 'utils/multicall'
import AAVE_POOL_DATA_PROVIDER_ABI from 'abis/lendle/ProtocolDataProvider.json'
import REWARDER_ABI from 'abis/aurelius/rewarder.json'
import UNI_V2_PAIR_ABI from 'abis/uniswap/uniswap-v2-pair.json'
import { BPS, TOKEN_META } from 'constants/1delta'
import { convertRateToApr, parseRawAmount } from 'utils/tableUtils/prices'
import { getAureliusTokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { LENDER_MODE_NO_MODE, Lender, RewardsMap } from 'types/lenderData/base'
import { formatAaveRawApyToApr } from 'utils/1delta/generalFormatters'
import { addressesAureliusATokens, addressesAureliusVTokens } from 'hooks/1delta/addressesAurelius'
import { getLenderAssets } from 'constants/getAssets'
import { getAaveTypeIncentivesControllerAddress, getAaveTypePoolDataProviderAddress } from 'hooks/1delta'
import { AURELIUS_REWARD_ASSETS } from './fetchUserData'
import { SupportedAssets } from 'types/1delta'

export enum AureliusTypeGetReserveDataIndexes {
  availableLiquidity = 0,
  totalStableDebt,
  totalVariableDebt,
  liquidityRate,
  variableBorrowRate,
  stableBorrowRate,
  averageStableBorrowRate,
  liquidityIndex,
  variableBorrowIndex,
  lastUpdateTimestamp,
}

export enum AureliusTypeGetReserveConfigurationData {
  decimals = 0,
  ltv,
  liquidationThreshold,
  liquidationBonus,
  reserveFactor,
  usageAsCollateralEnabled,
  borrowingEnabled,
  stableBorrowRateEnabled,
  isActive,
  isFrozen,
}

interface AureliusPoolReserveResponse {
  data: {
    [tokenSymbol: string]: {
      // token amounts
      totalDeposits: number;
      totalDebtStable: number;
      totalDebt: number;
      // USD amounts
      totalDepositsUSD: number;
      totalDebtStableUSD: number;
      totalDebtUSD: number;

      // reserve market data
      depositRate: number
      variableBorrowRate: number
      stableBorrowRate: number
      lastUpdateTimestamp?: number
      stakingYield: number

      // rewards
      rewards?: RewardsMap

      // reserve config
      decimals?: number
      usageAsCollateralEnabled?: boolean
      hasStable?: boolean

      // frozen
      isActive?: boolean
      isFrozen?: boolean
    }
  }
  config: {
    [tokenSymbol: string]: {
      decimals: number;

      config: {
        [0]: {
          modeId: 0,
          // collateral factors
          borrowCollateralFactor: number,
          collateralFactor: number,
          borrowFactor: number
        }
      }

      // flags
      collateralActive: boolean;
      borrowingEnabled: boolean;
      hasStable: boolean;
      isActive: boolean;
      isFrozen: boolean;
    }
  }
  chainId: number
}

interface AureliusReservesQueryParams {
  chainId: number
  prices: { [asset: string]: number }
  stakingYields: { [asset: string]: number; }
}

const getAbi = (lender: string) => {
  switch (lender) {
    case Lender.AURELIUS:
      return [...AAVE_POOL_DATA_PROVIDER_ABI, ...UNI_V2_PAIR_ABI, ...REWARDER_ABI]
    default:
      return []
  }
}

const buildLenderCall = (chainId: number, lender: string) => {
  switch (lender) {
    case Lender.AURELIUS:
      const providerAddress = getAaveTypePoolDataProviderAddress(chainId, Lender.AURELIUS)
      const incentivesController = getAaveTypeIncentivesControllerAddress(chainId, Lender.AURELIUS)
      const tokenDict = getAureliusTokenAddresses(chainId)
      const assetsToQuery = getLenderAssets(chainId, Lender.AURELIUS)
      const assets = assetsToQuery.map((a) => tokenDict[a])
      const [reward0, reward1] = AURELIUS_REWARD_ASSETS.map(t => t.address)
      const lTokens = getAddressesForChainIdFromAssetDict(addressesAureliusATokens, chainId, Lender.AURELIUS)
      const vTokens = getAddressesForChainIdFromAssetDict(addressesAureliusVTokens, chainId, Lender.AURELIUS)

      return [
        ...assets.flatMap((tk, i) => [
          {
            address: providerAddress,
            name: 'getReserveData',
            params: [tk],
          },
          {
            address: providerAddress,
            name: 'getReserveConfigurationData',
            params: [tk],
          },
          {
            address: incentivesController,
            name: 'getRewardsData',
            params: [lTokens[assetsToQuery[i]], reward0],
          },
          {
            address: incentivesController,
            name: 'getRewardsData',
            params: [lTokens[assetsToQuery[i]], reward1],
          },
          {
            address: incentivesController,
            name: 'getRewardsData',
            params: [vTokens[assetsToQuery[i]], reward0],
          },
          {
            address: incentivesController,
            name: 'getRewardsData',
            params: [vTokens[assetsToQuery[i]], reward1],
          },
        ]),
      ]
    default:
      return []
  }
}

const fetchAndConvertDataSlice = (
  lender: string,
  chainId: number,
  prices: { [asset: string]: number },
  stakingYields: { [asset: string]: number }
): [(data: any[]) => AureliusPoolReserveResponse | undefined, number] => {
  switch (lender) {
    case Lender.AURELIUS: {
      const tokenDict = getAureliusTokenAddresses(chainId)
      const names = Object.keys(tokenDict)
      const assetsToQuery = getLenderAssets(chainId, Lender.AURELIUS)
      const aTokenDict = getAddressesForChainIdFromAssetDict(addressesAureliusATokens, chainId, Lender.AURELIUS)
      const aTokenNames = Object.keys(aTokenDict)

      const expectedNumberOfCalls = assetsToQuery.length * 6
      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }

          const resultReserves: any = {}
          const resultConfig: any = {}

          for (let i = 0; i < assetsToQuery.length; i++) {
            const reserveData = data[i * 6]
            const configData = data[i * 6 + 1]

            const [, emission0PerSecondCollateral, ,] = data[i * 6 + 2]
            const [, emission1PerSecondCollateral, ,] = data[i * 6 + 3]
            const [, emission0PerSecondDebt, ,] = data[i * 6 + 4]
            const [, emission1PerSecondDebt, ,] = data[i * 6 + 5]
            const asset = names[i]
            const aTokenName = aTokenNames[i]

            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const totalStableDebt = parseRawAmount(reserveData?.[AureliusTypeGetReserveDataIndexes.totalStableDebt]?.toString(), decimals)
            const totalVariableDebt = parseRawAmount(reserveData?.[AureliusTypeGetReserveDataIndexes.totalVariableDebt]?.toString(), decimals)
            const liquidity = parseRawAmount(reserveData?.[AureliusTypeGetReserveDataIndexes.availableLiquidity]?.toString(), decimals)
            const totalAToken = liquidity + totalStableDebt + totalVariableDebt
            const price = prices[asset] ?? 1

            const totalDepositsUSD = totalAToken * price
            const totalDebtUSD = totalVariableDebt * price

            resultReserves[asset] = {
              // token amounts
              totalDeposits: totalAToken,
              totalDebtStable: totalStableDebt,
              totalDebt: totalVariableDebt,
              totalLiquidity: liquidity,
              // USD amounts
              totalDepositsUSD,
              totalDebtStableUSD: totalStableDebt * price,
              totalDebtUSD,
              totalLiquidityUSD: liquidity * price,
              // rates
              depositRate: formatAaveRawApyToApr(reserveData?.[AureliusTypeGetReserveDataIndexes.liquidityRate]?.toString()),
              variableBorrowRate: formatAaveRawApyToApr(reserveData?.[AureliusTypeGetReserveDataIndexes.variableBorrowRate]?.toString()),
              stableBorrowRate: formatAaveRawApyToApr(reserveData?.[AureliusTypeGetReserveDataIndexes.stableBorrowRate]?.toString()),
              stakingYield: stakingYields[asset] ?? 0,

              // rewards
              rewards: {
                [SupportedAssets.WMNT]: {
                  depositRate: convertRateToApr(parseRawAmount(emission0PerSecondCollateral)) / totalDepositsUSD * prices[SupportedAssets.WMNT],
                  variableBorrowRate: convertRateToApr(parseRawAmount(emission0PerSecondDebt)) / totalDebtUSD * prices[SupportedAssets.WMNT],
                  stableBorrowRate: 0,
                },
                // oAU allows for a 50% discount to pruchase AU, hence the price is the price of AU divided by 2
                [SupportedAssets.OAU]: {
                  depositRate: convertRateToApr(parseRawAmount(emission1PerSecondCollateral)) / totalDepositsUSD * prices['AU'] / 2,
                  variableBorrowRate: convertRateToApr(parseRawAmount(emission1PerSecondDebt)) / totalDebtUSD * prices['AU'] / 2,
                  stableBorrowRate: 0,
                },
              }
            }

            resultConfig[aTokenName] = {
              decimals: Number(configData?.[AureliusTypeGetReserveConfigurationData.decimals]),
              config: {
                [LENDER_MODE_NO_MODE]: {
                  modeId: LENDER_MODE_NO_MODE,
                  // collateral factors
                  borrowCollateralFactor: Number(configData?.[AureliusTypeGetReserveConfigurationData.ltv].toString()) / BPS,
                  collateralFactor: Number(configData?.[AureliusTypeGetReserveConfigurationData.liquidationThreshold].toString()) / BPS,
                  borrowFactor: 1
                }
              },
              liquidationBonus: Number(configData?.[AureliusTypeGetReserveConfigurationData.liquidationBonus].toString()) / BPS,
              // flags
              collateralActive: configData?.[AureliusTypeGetReserveConfigurationData.usageAsCollateralEnabled],
              borrowingEnabled: configData?.[AureliusTypeGetReserveConfigurationData.borrowingEnabled],
              hasStable: configData?.[AureliusTypeGetReserveConfigurationData.stableBorrowRateEnabled],
              isActive: configData?.[AureliusTypeGetReserveConfigurationData.isActive],
              isFrozen: configData?.[AureliusTypeGetReserveConfigurationData.isFrozen],
            }
          }

          return {
            data: resultReserves,
            config: resultConfig,
            chainId,
          }
        },
        expectedNumberOfCalls,
      ]
    }
    default: {
      return [() => undefined, 0]
    }
  }
}

const getLenderData = async (
  chainId: number,
  lenders: Lender[],
  prices: { [asset: string]: number },
  stakingYields: { [asset: string]: number }
): Promise<{ [lender: string]: AureliusPoolReserveResponse }> => {
  let calls: {
    call: {
      address: string
      name: string
      params: any[] | undefined
    }
    abi: any
  }[] = []

  for (const lender of lenders) {
    const abi = getAbi(lender)
    const callData = buildLenderCall(chainId, lender)
    const mappedCalls = callData.map((call) => ({ call, abi }))
    calls = [...calls, ...mappedCalls]
  }
  const rawResults = await multicallViem(
    chainId,
    calls.flatMap((call) => call.abi),
    calls.map((call) => call.call),
    1,
  )

  const invalidLenders: string[] = []
  const lenderData: { [lender: string]: AureliusPoolReserveResponse } = {}

  let currentSlice = 0
  for (const lender of lenders) {
    const [converter, sliceLength] = fetchAndConvertDataSlice(lender, chainId, prices, stakingYields)

    const data = rawResults.slice(currentSlice, currentSlice + sliceLength)
    const convertedData = converter(data)
    if (!convertedData) {
      invalidLenders.push(lender)
    } else {
      lenderData[lender] = convertedData
    }

    currentSlice += sliceLength
  }

  return lenderData
}

export const fetchAureliusPublicData: AsyncThunk<AureliusPoolReserveResponse, AureliusReservesQueryParams, any> =
  createAsyncThunk<AureliusPoolReserveResponse, AureliusReservesQueryParams>(
    'aurelius/fetchAureliusPublicData',

    async ({ chainId, prices, stakingYields }) => {
      try {
        const lenderData = await getLenderData(chainId, [Lender.AURELIUS], prices, stakingYields)
        return lenderData[Lender.AURELIUS]
      } catch (error) {
        console.error('Error fetching Aurelius public data:', error)
        return {
          data: {},
          config: {},
          chainId,
        }
      }
    }
  )