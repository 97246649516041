import { DexProtocol, Route, BasePool } from '@1delta/pool-sdk'
import { Currency, CurrencyAmount, TradeType } from '@1delta/base-sdk'
import { JsonRpcSigner } from '@ethersproject/providers'
import { SupportedChainId } from 'constants/chains'
import { InterfaceTrade } from 'state/routing/types'
import { FlashLoanProvider } from 'calldata/flashLoan/constants'

export type SerializedBigNumber = string
export type SerializedNumber = string

export interface TokenMeta {
  symbol: string
  decimals: number
  name: string
}

/** These enum values MUST be uppercase */
export enum SupportedAssets {
  UNI = 'UNI',
  WETH = 'WETH',
  DAI = 'DAI',
  LINK = 'LINK',
  USDCE = 'USDC.E',
  WBTC = 'WBTC',
  USDT = 'USDT',
  AAVE = 'AAVE',
  EURS = 'EURS',
  WMATIC = 'WMATIC',
  AGEUR = 'AGEUR',
  BAL = 'BAL',
  CRV = 'CRV',
  DPI = 'DPI',
  GHST = 'GHST',
  JEUR = 'JEUR',
  SUSHI = 'SUSHI',
  ETH = 'ETH',
  MATIC = 'MATIC',
  COMP = 'COMP',
  BAT = 'BAT',
  FEI = 'FEI',
  MKR = 'MKR',
  ZRX = 'ZRX',
  YFI = 'YFI',
  WBTC2 = 'WBTC2',
  USDP = 'USDP',
  TUSD = 'TUSD',
  SAI = 'SAI',
  REP = 'REP',
  MATICX = 'MATICX',
  MAI = 'MAI',
  STMATIC = 'STMATIC',
  WSTETH = 'WSTETH',
  GDAI = 'GDAI',
  VGHST = 'VGHST',
  GHO = 'GHO',
  USD = 'USD',
  EUR = 'EUR',
  FBTC = 'FBTC',
  // venus
  ADA = 'ADA',
  BCH = 'BCH',
  BETH = 'BETH',
  BTCB = 'BTCB',
  BUSD = 'BUSD',
  CAKE = 'CAKE',
  CAN = 'CAN',
  DOGE = 'DOGE',
  DOT = 'DOT',
  FIL = 'FIL',
  LTC = 'LTC',
  LUNA = 'LUNA',
  SXP = 'SXP',
  TRX = 'TRX',
  TRXOLD = 'TRXOLD',
  TUSDOLD = 'TUSDOLD',
  UST = 'UST',
  VAI = 'VAI',
  VRT = 'VRT',
  WBETH = 'WBETH',
  XRP = 'XRP',
  XVS = 'XVS',
  BNB = 'BNB',
  WBNB = 'WBNB',
  // the binance pegged stables have 18 decimals and are therefore treated differently
  BUSDC = 'BUSDC',
  BUSDT = 'BUSDT',
  WMNT = 'WMNT',
  MNT = 'MNT',
  USDC = 'USDC',
  // mantle
  METH = 'METH',
  USDY = 'USDY',
  USDE = 'USDE',
  USDY_MUSD = 'USDY_MUSD',
  OAU = 'OAU',
  AU = 'AU',
  // blast
  USDB = 'USDB',
  BLAST = 'BLAST',
  ANDY = 'ANDY',
  CBETH = 'CBETH',
  // avalanche
  AVAX = 'AVAX',
  WAVAX = 'WAVAX',
  // Taiko
  TAIKO = 'TAIKO',
  USDTE = 'USDT.E',
  // Rewards
  LEND = 'LEND',
}

export const toErc20Asset = (asset?: SupportedAssets | string | undefined): SupportedAssets => {
  if (!asset) return SupportedAssets.USDCE
  if (asset === SupportedAssets.MATIC) return SupportedAssets.WMATIC
  if (asset === SupportedAssets.ETH) return SupportedAssets.WETH
  if (asset === SupportedAssets.MNT) return SupportedAssets.WMNT
  return asset.toUpperCase() as SupportedAssets
}

export const hasChainLinkFeed = [
  SupportedAssets.WBTC,
  SupportedAssets.WBTC2,
  SupportedAssets.WETH,
  SupportedAssets.ETH,
  SupportedAssets.DAI,
  SupportedAssets.MATIC,
  SupportedAssets.LINK,
  SupportedAssets.USDCE
]


export const compoundAssets = [
  SupportedAssets.ETH,
  SupportedAssets.WBTC,
  SupportedAssets.USDCE,
  SupportedAssets.USDT,
  SupportedAssets.DAI,
  SupportedAssets.UNI,
  SupportedAssets.COMP,
]

export const aaveAssets = [
  SupportedAssets.WETH,
  SupportedAssets.DAI,
  SupportedAssets.LINK,
  SupportedAssets.USDCE,
  SupportedAssets.AAVE,
  SupportedAssets.GHO
]

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export enum PositionSides {
  Collateral = 'Collateral',
  Debt = 'Borrow',
}

export enum MarginTradeType {
  Open = 'Open',
  Close = 'Close',
  CollateralSwap = 'CollateralSwap',
  DebtSwap = 'DebtSwap',
}

export enum QuickActionType {
  Deposit = 'Deposit',
  Withdraw = 'Withdraw',
  Borrow = 'Borrow',
  Repay = 'Repay',
}

/** types that move funds from user to lender */
export const FROM_TYPES = [QuickActionType.Deposit, QuickActionType.Repay]
export const TO_TYPES = [QuickActionType.Withdraw, QuickActionType.Borrow]

export const DEBT_TYPES = [QuickActionType.Borrow, QuickActionType.Repay]
export const DEPOSIT_TYPES = [QuickActionType.Deposit, QuickActionType.Withdraw]

export enum AaveInterestMode {
  NONE = 0,
  STABLE = 1,
  VARIABLE = 2,
}

export interface MarginTradeState {
  [Field.INPUT]: { currencyId: string | undefined }
  [Field.OUTPUT]: { currencyId: string | undefined }
  typedValue: string,
  independentField: Field
  marginTradeType: MarginTradeType
  quickActionType: QuickActionType
  baseCurrency: SupportedAssets
  recipient?: string | null
}

export enum FeeAmount {
  LOW = 500,
  MEDIUM = 3000,
  HIGH = 10000,
}

const FEE_SIZE = 3

export function encodePath(path: string[], fees: (number | string)[]): string {
  if (path.length !== fees.length + 1) {
    throw new Error('path/fee lengths do not match')
  }

  let encoded = '0x'
  for (let i = 0; i < fees.length; i++) {
    // 20 byte encoding of the address
    encoded += path[i].slice(2)
    // 3 byte encoding of the fee
    encoded += fees[i].toString(16).padStart(2 * FEE_SIZE, '0')
  }
  // encode the final token
  encoded += path[path.length - 1].slice(2)

  return encoded.toLowerCase()
}

export type MappedCurrencyAmounts = { [field in Field]: CurrencyAmount<Currency> | undefined }

export type MappedCurrencies = { [field in Field]: Currency | undefined }

export enum TradeAggregator {
  OneDelta = '1delta',
  OneInch = '1inch',
  Paraswap = 'Paraswap',
  Odos = 'Odos',
  Kyberswap = 'Kyberswap',
  Bebop = 'Bebop',
  IceCreamSwap = 'IceCreamSwap',
  Magpie = 'Magpie',
}

const TRADE_AGGREGATORS_PER_CHAIN = {
  [SupportedChainId.POLYGON]: [TradeAggregator.OneDelta, TradeAggregator.Paraswap, TradeAggregator.OneInch, TradeAggregator.Odos, TradeAggregator.Kyberswap, TradeAggregator.Bebop, TradeAggregator.Magpie],
  [SupportedChainId.BSC]: [TradeAggregator.Paraswap, TradeAggregator.OneInch, TradeAggregator.Odos, TradeAggregator.Kyberswap, TradeAggregator.Bebop, TradeAggregator.Magpie],
  [SupportedChainId.MANTLE]: [TradeAggregator.OneDelta, TradeAggregator.Odos, TradeAggregator.Kyberswap],
  [SupportedChainId.ARBITRUM_ONE]: [TradeAggregator.Paraswap, TradeAggregator.OneInch, TradeAggregator.Odos, TradeAggregator.Kyberswap, TradeAggregator.Bebop, TradeAggregator.Magpie],
  [SupportedChainId.BASE]: [TradeAggregator.Paraswap, TradeAggregator.OneInch, TradeAggregator.Odos, TradeAggregator.Kyberswap, TradeAggregator.Bebop, TradeAggregator.IceCreamSwap, , TradeAggregator.Magpie],
  [SupportedChainId.AVALANCHE]: [TradeAggregator.Paraswap, TradeAggregator.OneInch, TradeAggregator.Odos, TradeAggregator.Kyberswap, TradeAggregator.Magpie],
  [SupportedChainId.OPTIMISM]: [TradeAggregator.Paraswap, TradeAggregator.OneInch, TradeAggregator.Odos, TradeAggregator.Kyberswap, TradeAggregator.Bebop, TradeAggregator.Magpie],
  [SupportedChainId.BLAST]: [TradeAggregator.Kyberswap, TradeAggregator.IceCreamSwap, TradeAggregator.Magpie],
  [SupportedChainId.LINEA]: [TradeAggregator.Odos, TradeAggregator.Kyberswap, TradeAggregator.IceCreamSwap],
  [SupportedChainId.TAIKO]: [TradeAggregator.OneDelta, TradeAggregator.Bebop, TradeAggregator.IceCreamSwap, TradeAggregator.Magpie],
}

export function getAvailableAggregators(chainId: number): TradeAggregator[] {
  return TRADE_AGGREGATORS_PER_CHAIN[chainId] ?? []
}

export interface GenericTrade {
  tradeType: TradeType
  aggregator: TradeAggregator,
  inputAmount: CurrencyAmount<Currency>,
  outputAmount: CurrencyAmount<Currency>,
  oneDeltaTrade?: InterfaceTrade
  calldata?: string
  executeOrder?: (signer: JsonRpcSigner) => Promise<string>
  target?: string
  approvalTarget?: string
  stringified: string
  flashLoanSource?: FlashLoanProvider
}


export interface MappedSwapAmounts {
  [asset: string]: { amount: number, type: AaveInterestMode }
}

/**
 * Mapping ofn INPUT/OUTPUT to swap amount data: asset, amount, type
 */
export interface DirectionedSwapAmounts {
  [field: string]: { asset: string, amount: number, type: AaveInterestMode }
}

export const STAKER_TOKENS = [
  SupportedAssets.WSTETH,
  SupportedAssets.STMATIC,
  SupportedAssets.MATICX
]

export const ETHEREUM_TOKENS = [
  SupportedAssets.WSTETH,
  SupportedAssets.CBETH,
  SupportedAssets.WETH,
  SupportedAssets.WBETH,
  SupportedAssets.BETH,
  SupportedAssets.METH
]

export const MATIC_TOKENS = [
  SupportedAssets.WMATIC,
  SupportedAssets.STMATIC,
  SupportedAssets.MATICX,
]

export const STABLECOINS_USD = [
  SupportedAssets.USDCE,
  SupportedAssets.USDC,
  SupportedAssets.USDT,
  SupportedAssets.DAI,
  SupportedAssets.USDP,
  SupportedAssets.USDY,
  SupportedAssets.USDE,
  SupportedAssets.BUSD,
  SupportedAssets.BUSDC,
  SupportedAssets.BUSDT,
  SupportedAssets.MAI,
]

export const STABLECOINS_EUR = [
  SupportedAssets.AGEUR,
  SupportedAssets.EURS,
  SupportedAssets.JEUR
]

export const ALL_STABLE_ASSETS = [
  ...STABLECOINS_USD,
  ...STABLECOINS_EUR
]

export const BLUE_CHIP = [
  SupportedAssets.WBTC,
  SupportedAssets.WBTC2,
  SupportedAssets.WETH,
  SupportedAssets.ETH,
  SupportedAssets.MATIC,
  SupportedAssets.WMATIC,
  SupportedAssets.WSTETH,
  SupportedAssets.METH,
  SupportedAssets.DAI,
  SupportedAssets.USDCE,
  SupportedAssets.USDC,
  SupportedAssets.USDT
]

/** Native asset symbols per chain */
export const NATIVE_ASSET: { [chainId: number]: SupportedAssets } = {
  [SupportedChainId.MAINNET]: SupportedAssets.ETH,
  [SupportedChainId.GOERLI]: SupportedAssets.ETH,
  [SupportedChainId.POLYGON]: SupportedAssets.MATIC,
  [SupportedChainId.POLYGON_MUMBAI]: SupportedAssets.MATIC,
  [SupportedChainId.MANTLE]: SupportedAssets.MNT,
  [SupportedChainId.BSC]: SupportedAssets.BNB,
  [SupportedChainId.ARBITRUM_ONE]: SupportedAssets.ETH,
  [SupportedChainId.BASE]: SupportedAssets.ETH,
  [SupportedChainId.AVALANCHE]: SupportedAssets.AVAX,
  [SupportedChainId.OPTIMISM]: SupportedAssets.ETH,
  [SupportedChainId.BLAST]: SupportedAssets.ETH,
  [SupportedChainId.LINEA]: SupportedAssets.ETH,
  [SupportedChainId.TAIKO]: SupportedAssets.ETH,
}

/** Wrapped native asset symbols per chain */
export const WRAPPED_NATIVE_ASSET: { [chainId: number]: SupportedAssets } = {
  [SupportedChainId.MAINNET]: SupportedAssets.WETH,
  [SupportedChainId.GOERLI]: SupportedAssets.WETH,
  [SupportedChainId.POLYGON]: SupportedAssets.WMATIC,
  [SupportedChainId.POLYGON_MUMBAI]: SupportedAssets.WMATIC,
  [SupportedChainId.MANTLE]: SupportedAssets.WMNT,
  [SupportedChainId.BSC]: SupportedAssets.WBNB,
  [SupportedChainId.ARBITRUM_ONE]: SupportedAssets.WETH,
  [SupportedChainId.BASE]: SupportedAssets.WETH,
  [SupportedChainId.AVALANCHE]: SupportedAssets.WAVAX,
  [SupportedChainId.OPTIMISM]: SupportedAssets.WETH,
  [SupportedChainId.BLAST]: SupportedAssets.WETH,
  [SupportedChainId.LINEA]: SupportedAssets.WETH,
  [SupportedChainId.TAIKO]: SupportedAssets.WETH,
}

/**
 * Convert a string (asset symbol) to a Supported asset, considering also naming conflicts
 * @param a string
 * @returns SupportedAsset
 */
export const getFallbackAsset = (a: string): SupportedAssets => {
  const upperA = a?.toUpperCase()
  if (upperA === 'MIMATIC') return SupportedAssets.MAI
  if (upperA === 'ETH') return SupportedAssets.WETH
  if (upperA === 'MNT') return SupportedAssets.WMNT
  return upperA as SupportedAssets
}

/**
 * Convert a string (asset symbol) to a Supported asset, considering also naming conflicts
 * @param a string
 * @returns SupportedAsset
 */
export const toOracleKey = (a: string): SupportedAssets => {
  const upperA = a?.toUpperCase()
  if (upperA === 'MIMATIC') return SupportedAssets.MAI
  if (upperA === 'ETH') return SupportedAssets.WETH
  if (upperA === 'MNT') return SupportedAssets.WMNT
  if (upperA === 'MATIC') return SupportedAssets.WMATIC
  if (upperA === 'USDC.E') return SupportedAssets.USDC
  if (upperA === 'USDT.E') return SupportedAssets.USDT
  if (upperA === 'USDT.E') return SupportedAssets.USDT
  if (upperA === 'BUSDC') return SupportedAssets.USDC
  if (upperA === 'BTCB') return SupportedAssets.WBTC
  if (upperA === 'BUSDT') return SupportedAssets.USDT
  return upperA as SupportedAssets
}

export const DEX_PROTOCOL_TO_NAME: { [aggregator: string]: string } = {
  [DexProtocol.UNISWAP_V3]: 'Uniswap V3',
  [DexProtocol.QUICKSWAP_V3]: 'Quickswap V3',
  [DexProtocol.RETRO]: 'Retro',
  [DexProtocol.QUICKSWAP_V2]: 'Quickswap V2',
  [DexProtocol.SUSHISWAP_V3]: 'Sushiswap V3',
  [DexProtocol.SUSHISWAP_V2]: 'Sushiswap V2',
  [DexProtocol.DFYN]: 'Dfyn',
  [DexProtocol.FUSIONX_V2]: 'FusionX V2',
  [DexProtocol.MERCHANT_MOE]: 'Merchant Moe',
  [DexProtocol.MERCHANT_MOE_LB]: 'Merchant Moe LB',
  [DexProtocol.FUSIONX_V3]: 'FusionX V3',
  [DexProtocol.IZUMI]: 'Izumi',
  [DexProtocol.AGNI]: 'Agni',
  [DexProtocol.CRUST]: 'Crust',
  [DexProtocol.CRUST_V1_STABLE]: 'Crust V1 Stable',
  [DexProtocol.CRUST_V1_VOLATILE]: 'Crust V1 Volatile',
  [DexProtocol.DYSTOPIA_STABLE]: 'Dystopia Stable',
  [DexProtocol.DYSTOPIA_VOLATILE]: 'Dystopia Volatile',
  [DexProtocol.APESWAP]: 'Apeswap',
  [DexProtocol.COMETH]: 'Cometh',
  [DexProtocol.WAULTSWAP]: 'Waultswap',
  [DexProtocol.POLYCAT]: 'Polycat',
  [DexProtocol.UNISWAP_V2]: 'Uniswap V2',
  [DexProtocol.METHLAB]: 'MethLab',
  [DexProtocol.KTX]: 'KTX',
  [DexProtocol.WOO_FI]: 'WOOFi',
  [DexProtocol.SWAPSICLE]: 'Swapsicle',
  [DexProtocol.BUTTER]: 'Butter',
  [DexProtocol.CLEOPATRA]: 'Cleopatra',
  [DexProtocol.VELOCIMETER_STABLE]: 'Velocimeter Stable',
  [DexProtocol.VELOCIMETER_VOLATILE]: 'Velocimeter Volatile',
  [DexProtocol.CLEOPATRA_V1_STABLE]: 'Cleopatra V1 Stable',
  [DexProtocol.CLEOPATRA_V1_VOLATILE]: 'Cleopatra V1 Volatile',
  [DexProtocol.STRATUM_STABLE]: 'Stratum Stable',
  [DexProtocol.STRATUM_VOLATILE]: 'Stratum Volatile',
  [DexProtocol.STRATUM_CURVE_STABLE]: 'Stratum Curve 3mUSD',
  [DexProtocol.STRATUM_CURVE]: 'Stratum Curve',
  [DexProtocol.TROPICAL_SWAP]: 'Tropical Swap',
  [DexProtocol.MANTLESWAP]: 'MantleSwap',
  [DexProtocol.TAIKOSWAP]: 'Taikoswap',
  [DexProtocol.DODO_V2]: 'Dodo V2',
  [DexProtocol.KODO_STABLE]: 'Kodo Stable',
  [DexProtocol.KODO_VOLATILE]: 'Kodo Volatile',
  [DexProtocol.DTX]: 'DTX',
  [DexProtocol.DTX_V1]: 'DTX V1',
  [DexProtocol.HENJIN]: 'Henjin',
  [DexProtocol.RITSU_BASE]: 'Ritsu Base',
  [DexProtocol.RITSU_STABLE]: 'Ritsu Stable',
  [DexProtocol.RITSU_RYTHM]: 'Ritsu Rythm',
}

// map aggregator to its pool id
// map aggregator to its pool id
export const DEX_PROTOCOL_TO_POOL_ID: { [aggregator: string]: { [chainId: number]: number } } = {
  [DexProtocol.UNISWAP_V3]: {
    [SupportedChainId.POLYGON]: 0,
    [SupportedChainId.MANTLE]: 6,
    [SupportedChainId.TAIKO]: 0,
  },
  [DexProtocol.RETRO]: {
    [SupportedChainId.POLYGON]: 1,
  },
  [DexProtocol.SUSHISWAP_V3]: {
    [SupportedChainId.POLYGON]: 2,
  },
  [DexProtocol.QUICKSWAP_V3]: {
    [SupportedChainId.POLYGON]: 3,
  },
  [DexProtocol.UNISWAP_V2]: {
    [SupportedChainId.POLYGON]: 100,
  },
  [DexProtocol.QUICKSWAP_V2]: {
    [SupportedChainId.POLYGON]: 101,
  },
  [DexProtocol.SUSHISWAP_V2]: {
    [SupportedChainId.POLYGON]: 102,
  },
  [DexProtocol.DFYN]: {
    [SupportedChainId.POLYGON]: 103,
  },
  [DexProtocol.POLYCAT]: {
    [SupportedChainId.POLYGON]: 104,
  },
  [DexProtocol.APESWAP]: {
    [SupportedChainId.POLYGON]: 105,
  },
  [DexProtocol.COMETH]: {
    [SupportedChainId.POLYGON]: 106,
  },
  [DexProtocol.WAULTSWAP]: {
    [SupportedChainId.POLYGON]: 107,
  },
  [DexProtocol.DYSTOPIA_VOLATILE]: {
    [SupportedChainId.POLYGON]: 120,
  },
  [DexProtocol.DYSTOPIA_STABLE]: {
    [SupportedChainId.POLYGON]: 135,
  },
  [DexProtocol.FUSIONX_V2]: {
    [SupportedChainId.MANTLE]: 100,
  },
  [DexProtocol.MERCHANT_MOE]: {
    [SupportedChainId.MANTLE]: 101,
  },
  [DexProtocol.TROPICAL_SWAP]: {
    [SupportedChainId.MANTLE]: 102,
  },
  [DexProtocol.MANTLESWAP]: {
    [SupportedChainId.MANTLE]: 103,
  },
  [DexProtocol.VELOCIMETER_VOLATILE]: {
    [SupportedChainId.MANTLE]: 122,
  },
  [DexProtocol.VELOCIMETER_STABLE]: {
    [SupportedChainId.MANTLE]: 137,
  },
  [DexProtocol.CLEOPATRA_V1_VOLATILE]: {
    [SupportedChainId.MANTLE]: 120,
  },
  [DexProtocol.CLEOPATRA_V1_STABLE]: {
    [SupportedChainId.MANTLE]: 135,
  },
  [DexProtocol.KODO_VOLATILE]: {
    [SupportedChainId.TAIKO]: 120,
  },
  [DexProtocol.KODO_STABLE]: {
    [SupportedChainId.TAIKO]: 135,
  },
  [DexProtocol.STRATUM_VOLATILE]: {
    [SupportedChainId.MANTLE]: 121,
  },
  [DexProtocol.STRATUM_STABLE]: {
    [SupportedChainId.MANTLE]: 136,
  },
  [DexProtocol.CRUST_V1_VOLATILE]: {
    [SupportedChainId.MANTLE]: 123,
  },
  [DexProtocol.CRUST_V1_STABLE]: {
    [SupportedChainId.MANTLE]: 138,
  },
  [DexProtocol.CRUST]: {
    [SupportedChainId.MANTLE]: 7,
  },
  [DexProtocol.STRATUM_CURVE_STABLE]: {
    [SupportedChainId.MANTLE]: 50,
  },
  [DexProtocol.STRATUM_CURVE]: {
    [SupportedChainId.MANTLE]: 51,
  },
  [DexProtocol.MERCHANT_MOE_LB]: {
    [SupportedChainId.MANTLE]: 151,
  },
  [DexProtocol.FUSIONX_V3]: {
    [SupportedChainId.MANTLE]: 0,
  },
  [DexProtocol.IZUMI]: {
    [SupportedChainId.MANTLE]: 49,
    [SupportedChainId.POLYGON]: 49,
    [SupportedChainId.TAIKO]: 49,
  },
  [DexProtocol.WOO_FI]: {
    [SupportedChainId.MANTLE]: 150,
    [SupportedChainId.POLYGON]: 150,
  },
  [DexProtocol.AGNI]: {
    [SupportedChainId.MANTLE]: 1,
  },
  [DexProtocol.SWAPSICLE]: {
    [SupportedChainId.MANTLE]: 2,
  },
  [DexProtocol.BUTTER]: {
    [SupportedChainId.MANTLE]: 3,
  },
  [DexProtocol.CLEOPATRA]: {
    [SupportedChainId.MANTLE]: 4,
  },
  [DexProtocol.METHLAB]: {
    [SupportedChainId.MANTLE]: 5,
  },
  [DexProtocol.KTX]: {
    [SupportedChainId.MANTLE]: 152,
  },
  [DexProtocol.DODO_V2]: {
    [SupportedChainId.MANTLE]: 153,
  },
  [DexProtocol.DTX]: {
    [SupportedChainId.TAIKO]: 1,
  },
  [DexProtocol.DTX_V1]: {
    [SupportedChainId.TAIKO]: 100,
  },
  [DexProtocol.HENJIN]: {
    [SupportedChainId.TAIKO]: 2,
  },
  [DexProtocol.RITSU_BASE]: {
    [SupportedChainId.TAIKO]: 150,
  },
  [DexProtocol.RITSU_STABLE]: {
    [SupportedChainId.TAIKO]: 150,
  },
  [DexProtocol.RITSU_RYTHM]: {
    [SupportedChainId.TAIKO]: 150,
  },
  [DexProtocol.TAIKOSWAP]: {
    [SupportedChainId.TAIKO]: 101,
  },
}

export const AAVE_ISOLATED_ASSETS: { [chainId: number]: SupportedAssets[] } = {
  [SupportedChainId.POLYGON]: [SupportedAssets.MAI, SupportedAssets.EURS],
  [SupportedChainId.POLYGON_MUMBAI]: [],
  [SupportedChainId.MANTLE]: [],
  [SupportedChainId.GOERLI]: [],
  [SupportedChainId.MAINNET]: [],
}

export interface DexscreenerPair {
  chainId: string;
  dexId: string;
  url: string;
  pairAddress: string;
  baseToken: {
    address: string;
    name: string;
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceNative: string;
  priceUsd?: string;
  txns: {
    m5: {
      buys: number;
      sells: number;
    };
    h1: {
      buys: number;
      sells: number;
    };
    h6: {
      buys: number;
      sells: number;
    };
    h24: {
      buys: number;
      sells: number;
    };
  };
  volume?: {
    m5?: number;
    h1?: number;
    h6?: number;
    h24?: number;
  };
  priceChange?: {
    m5?: number;
    h1?: number;
    h6?: number;
    h24?: number;
  };
  liquidity?: {
    usd?: number;
    base: number;
    quote: number;
  };
  fdv?: number;
  marketCap?: number;
  pairCreatedAt?: number;
}

export interface DexscreenerPairReduced {
  tokenAddress: string
  pairAddress: string
  priceUsd?: number
  userBalance?: number
  userBalanceUsd?: number
  assetId: string
  assetName: string
  liquidityUsd?: number
  volume24hUsd?: number
  priceChange24h?: number
  fdv?: number
  marketCap?: number
}

export const chainIdToDexscreenerChainId = {
  [SupportedChainId.MAINNET]: 'ethereum',
  [SupportedChainId.POLYGON]: 'polygon',
  [SupportedChainId.MANTLE]: 'mantle',
  [SupportedChainId.BSC]: 'bsc',
  [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
  [SupportedChainId.BASE]: 'base',
  [SupportedChainId.AVALANCHE]: 'avalanche',
  [SupportedChainId.OPTIMISM]: 'optimism',
  [SupportedChainId.BLAST]: 'blast',
  [SupportedChainId.LINEA]: 'linea',
  [SupportedChainId.TAIKO]: 'taiko',
}

export const defaultDexscreenerPairAddress = {
  [SupportedChainId.MAINNET]: '0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640',
  [SupportedChainId.POLYGON]: '0xA374094527e1673A86dE625aa59517c5dE346d32',
  [SupportedChainId.MANTLE]: '0x54169896d28dec0FFABE3B16f90f71323774949f',
  [SupportedChainId.BSC]: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
  [SupportedChainId.ARBITRUM_ONE]: '0xc6962004f452be9203591991d15f6b388e09e8d0',
  [SupportedChainId.BASE]: '0xcdac0d6c6c59727a65f871236188350531885c43',
  [SupportedChainId.AVALANCHE]: '0x42c701d4e359178412d014506cfac43a67e57d6d',
  [SupportedChainId.OPTIMISM]: '0x85149247691df622eaf1a8bd0cafd40bc45154a9',
  [SupportedChainId.LINEA]: '0x5ec5b1e9b1bd5198343abb6e55fb695d2f7bb308',
  [SupportedChainId.BLAST]: '0x32fdf42095aaecfea06f8ec26a361475f3603cb9',
}