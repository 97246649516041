import { createReducer } from '@reduxjs/toolkit'
import {
  resetCompoundV3UserState,
  resetState,
} from './actions'
import { fetchCompoundV3PublicData } from './fetchPublicData'
import { fetchCompoundV3UserData } from './fetchUserData'
import { CompoundV3State } from 'types/lenderData/compound-v3'
import { SupportedAssets } from 'types/1delta'
import { STATE_CHAINIDS } from 'constants/chains'
import { safeDivide } from 'utils/1delta/generalFormatters'
import { divideAccrualsToAprs } from '../utils'

export const compoundV3AssetKey = (baseAsset: SupportedAssets | string, asset: SupportedAssets | string) => `${baseAsset}-${asset}`

const emptyData = {
  publicLoaded: false,
  isAllowed: {},
  lenderData: {},
  userData: {},
  lenderRewards: 0,
  balanceData: {},
  aprData: {},
}

export const initialState: CompoundV3State = Object.assign(
  {}, ...STATE_CHAINIDS.map(cId => { return { [cId]: emptyData } })
)

export default createReducer<CompoundV3State>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    .addCase(resetCompoundV3UserState, (state, action) => {
      const chainId = action.payload.chainId
      if (state[chainId])
        state[chainId].userData = {}
    })
    // new reserve data-fetch using aave data provider
    .addCase(fetchCompoundV3PublicData.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      const chainId = action.payload.chainId
      if (assetKeys.length === 0) return; // prevents setting load flags
      if (!state[chainId]) state[chainId] = emptyData
      // assign public data
      for (let i = 0; i < assetKeys.length; i++) {
        const asset = assetKeys[i]
        state[chainId].lenderData[asset] = {
          ...state[chainId].lenderData[asset],
          ...action.payload.data[asset],
        }
      }
      state[chainId].publicLoaded = true
    })
    .addCase(fetchCompoundV3PublicData.pending, (state) => {
      //
    })
    // user data from provider
    .addCase(fetchCompoundV3UserData.fulfilled, (state, action) => {
      let assetKeys = Object.keys(action.payload.tokensData)
      const { chainId, account } = action.payload
      if (!state[chainId]) state[chainId] = emptyData

      // organic yields
      let depositInterest = 0
      let borrowInterest = 0
      // rewards
      let rewardDepositAccrual = 0
      let rewardBorrowAccrual = 0
      // staking
      let stakingDepositAccrual = 0
      let stakingBorrowAccrual = 0
      // amountrs
      let deposits = 0
      let debt = 0
      let collateral = 0
      let borrowDiscountedCollateral = 0

      const rewardsPerAsset = {
        [SupportedAssets.COMP]: {
          apr: 0,
          borrowApr: 0,
          depositApr: 0,
        },
      }

      // main user data
      for (let i = 0; i < assetKeys.length; i++) {
        const asset = assetKeys[i]
        if (!state[chainId].lenderData[asset]) continue;
        const { depositsUSD, debtUSD } = action.payload.tokensData[asset]
        const { depositRate, stakingYield, variableBorrowRate, rewards } = state[chainId].lenderData[asset]

        // amounts
        deposits += depositsUSD
        debt += debtUSD
        // rewards 
        // rewards 
        Object.entries(rewards ?? {}).map(([key, rewardData]) => {
          rewardDepositAccrual += rewardData.depositRate * depositsUSD
          rewardBorrowAccrual += rewardData.variableBorrowRate * debtUSD
          // totals
          const rewDepo = rewardData.depositRate * depositsUSD
          const rewDebt = rewardData.variableBorrowRate * debtUSD
          rewardsPerAsset[key].depositApr += rewDepo
          rewardsPerAsset[key].borrowApr += rewDebt
        })
        // staking
        stakingDepositAccrual += (stakingYield ?? 0) * depositsUSD
        stakingBorrowAccrual += (stakingYield ?? 0) * debtUSD
        // risk adjusted
        collateral += (state[chainId].lenderData[asset].config?.[0]?.collateralFactor ?? 1) * depositsUSD
        borrowDiscountedCollateral += (state[chainId].lenderData[asset].config?.[0]?.borrowCollateralFactor ?? 1) * depositsUSD
        // IRs
        depositInterest += depositRate * depositsUSD
        borrowInterest += debtUSD * variableBorrowRate
        if (!state[chainId].userData[account]) state[chainId].userData[account] = {}
        // add data to the state
        state[chainId].userData[account][asset] = {
          ...state[chainId].userData[account][asset],
          ...action.payload.tokensData[asset],
        }
      }
      const nav = deposits - debt
      // aggregated balance data
      state[chainId].balanceData[account] = {
        borrowDiscountedCollateral,
        collateral,
        deposits,
        debt,
        adjustedDebt: debt,
        nav,
        rewards: action.payload.compRewards
      }
      if (!state[chainId].aprData) state[chainId].aprData = {}
      // aggregated apr data
      state[chainId].aprData[account] = {
        apr: safeDivide(depositInterest - borrowInterest, nav),
        borrowApr: safeDivide(borrowInterest, debt),
        depositApr: safeDivide(depositInterest, deposits),
        rewards: divideAccrualsToAprs(rewardsPerAsset, nav, deposits, debt),
        rewardApr: safeDivide(rewardDepositAccrual + rewardBorrowAccrual, nav),
        rewardDepositApr: safeDivide(rewardDepositAccrual, deposits),
        rewardBorrowApr: safeDivide(rewardBorrowAccrual, debt),
        stakingApr: safeDivide(stakingDepositAccrual - stakingBorrowAccrual, nav),
        stakingDepositApr: safeDivide(stakingDepositAccrual, deposits),
        stakingBorrowApr: safeDivide(stakingBorrowAccrual, debt),
      }
      state[chainId].isAllowed[action.payload.baseAsset] = action.payload.isAllowed
      state[chainId].lenderRewards = action.payload.compRewards
    })
    .addCase(fetchCompoundV3UserData.pending, (state) => {
      //
    })
)
