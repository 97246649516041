import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { ChainId } from '@1delta/base-sdk'
import { SerializedBigNumber } from 'types/1delta'
import { Call, multicallViem } from 'utils/multicall'
import AAVE_POOL_AND_DATA_PROVIDER_ABI from 'abis/aave/AAVEPoolAndDataProvider.json'
import STABLE_DEBT_TOKEN_ABI from 'abis/aave/StableDebtToken.json'
import REWARDER_ABI from 'abis/aurelius/rewarder.json'
import { TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { getAureliusTokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { getAddressesForChainIdFromAssetDict } from 'hooks/1delta/addresses'
import { Lender } from 'types/lenderData/base'
import { ONE_DELTA_COMPOSER } from 'hooks/1delta/addresses1Delta'
import { addressesAureliusCore, addressesAureliusATokens, addressesAureliusSTokens, addressesAureliusVTokens } from 'hooks/1delta/addressesAurelius'
import { getLenderAssets } from 'constants/getAssets'
import { SupportedChainId } from 'constants/chains'
import { OAU_MANTLE, WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { getAaveTypePoolDataProviderAddress } from 'hooks/1delta'

export const AURELIUS_REWARD_ASSETS = [WRAPPED_NATIVE_CURRENCY[ChainId.MANTLE], OAU_MANTLE]

const fallbackDebtToken = '0x52A1CeB68Ee6b7B5D13E0376A1E0E4423A8cE26e'

export enum AureliusTypeGetUserReserveData {
  currentATokenBalance = 0,
  currentStableDebt,
  currentVariableDebt,
  principalStableDebt,
  scaledVariableDebt,
  stableBorrowRate,
  liquidityRate,
  stableRateLastUpdated,
  usageAsCollateralEnabled,
}

interface AureliusUserReserveResponse {
  chainId: number
  account: string
  tokensData: {
    [tokenSymbol: string]: {
      // token amounts
      deposits: number
      debt: number
      debtStable: number
      // usd amounts
      depositsUSD: number
      debtUSD: number
      debtStableUSD: number

      collateralActive: boolean
      claimableRewards: number
    }
  },
  rewards: {
    [tokenSymbol: string]: number
  }
  allowances: { [asset: string]: AllowanceData }
}

interface AllowanceData {
  allowanceDepositDirect: SerializedBigNumber
  allowanceWithdrawal: SerializedBigNumber
  allowanceBorrowingVariable: SerializedBigNumber
  allowanceBorrowingStable: SerializedBigNumber
}

interface AureliusUserReservesQueryParams {
  chainId: number
  account: string
  prices: { [asset: string]: number }
}

export const getAbi = (lender: Lender) => {
  switch (lender) {
    case Lender.AURELIUS:
      return [...AAVE_POOL_AND_DATA_PROVIDER_ABI, ...STABLE_DEBT_TOKEN_ABI, ...REWARDER_ABI]
    default:
      return []
  }
}

export const buildLenderCall = (chainId: number, lender: Lender, account: string): Call[] => {
  switch (lender) {
    case Lender.AURELIUS:
      const broker = ONE_DELTA_COMPOSER[chainId]
      const aureliusPool = addressesAureliusCore.PoolProxy[chainId]
      const rewarder = addressesAureliusCore.IncentivesController[chainId]
      const providerAddress = getAaveTypePoolDataProviderAddress(chainId, Lender.AURELIUS)
      const tokenDict = getAureliusTokenAddresses(chainId)
      const assetsToQuery = getLenderAssets(chainId, Lender.AURELIUS)

      const lTokens = getAddressesForChainIdFromAssetDict(addressesAureliusATokens, chainId, Lender.AURELIUS)
      const vTokens = getAddressesForChainIdFromAssetDict(addressesAureliusVTokens, chainId, Lender.AURELIUS)
      const sTokens = getAddressesForChainIdFromAssetDict(addressesAureliusSTokens, chainId, Lender.AURELIUS)

      return [
        ...assetsToQuery.flatMap((tk) => [
          {
            address: providerAddress,
            name: 'getUserReserveData',
            params: [tokenDict[tk], account],
          },
          {
            address: tokenDict[tk],
            name: 'allowance',
            params: [account, aureliusPool],
          },
          {
            address: lTokens[tk],
            name: 'allowance',
            params: [account, broker],
          },
          {
            address: vTokens[tk] ?? fallbackDebtToken,
            name: 'borrowAllowance',
            params: [account, broker],
          },
          {
            address: sTokens[tk] ?? fallbackDebtToken,
            name: 'borrowAllowance',
            params: [account, broker],
          },
        ]),
        {
          address: rewarder,
          name: 'getAllUserRewardsBalance',
          params: [
            [
              ...Object.values(lTokens),
              ...Object.values(vTokens)
            ],
            account
          ],
        }
      ]
    default:
      return []
  }
}

export const fetchAndConvertDataSlice = (
  lender: Lender,
  chainId: number,
  account: string,
  prices: { [asset: string]: number }
): [(data: any[]) => AureliusUserReserveResponse | undefined, number] => {
  switch (lender) {
    case Lender.AURELIUS: {
      const assetsToQuery = getLenderAssets(chainId, Lender.AURELIUS)
      const expectedNumberOfCalls = assetsToQuery.length * 5 + 1

      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }

          const tokensData: { [asset: string]: any } = {}
          const allowances: { [asset: string]: AllowanceData } = {}

          for (let i = 0; i < assetsToQuery.length; i++) {
            const asset = assetsToQuery[i]
            const reserveData = data[i * 5]
            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const price = prices[asset] ?? 1

            const currentATokenBalance = parseRawAmount(reserveData[AureliusTypeGetUserReserveData.currentATokenBalance].toString(), decimals)
            const currentStableDebt = parseRawAmount(reserveData[AureliusTypeGetUserReserveData.currentStableDebt].toString(), decimals)
            const currentVariableDebt = parseRawAmount(reserveData[AureliusTypeGetUserReserveData.currentVariableDebt].toString(), decimals)

            tokensData[asset] = {
              deposits: currentATokenBalance,
              debtStable: currentStableDebt,
              debt: currentVariableDebt,
              depositsUSD: currentATokenBalance * price,
              debtStableUSD: currentStableDebt * price,
              debtUSD: currentVariableDebt * price,
              stableBorrowRate: reserveData[AureliusTypeGetUserReserveData.stableBorrowRate].toString(),
              collateralActive: Boolean(reserveData[AureliusTypeGetUserReserveData.usageAsCollateralEnabled]),
              claimableRewards: 0,
            }

            allowances[asset] = {
              allowanceDepositDirect: data[i * 5 + 1].toString(),
              allowanceWithdrawal: data[i * 5 + 2].toString(),
              allowanceBorrowingVariable: data[i * 5 + 3].toString(),
              allowanceBorrowingStable: data[i * 5 + 4].toString(),
            }
          }

          const startIndex = assetsToQuery.length * 5
          const rewardMapping = Object.fromEntries(data[startIndex][0].map((key: string, index: number) => [key.toLowerCase(), data[startIndex][1][index]]))
          const rewards: { [asset: string]: number } = {}
          for (let i = 0; i < AURELIUS_REWARD_ASSETS.length; i++) {
            const asset = AURELIUS_REWARD_ASSETS[i]
            rewards[asset.symbol] = parseRawAmount(rewardMapping[asset.address.toLowerCase()].toString(), asset.decimals)
          }

          return {
            chainId,
            account,
            tokensData,
            rewards,
            allowances
          }
        },
        expectedNumberOfCalls,
      ]
    }
    default: {
      return [() => undefined, 0]
    }
  }
}

export const getLenderData = async (
  chainId: number,
  lenders: Lender[],
  account: string,
  prices: { [asset: string]: number }
): Promise<{ [lender: string]: any }> => {
  let calls: {
    call: Call
    abi: any
  }[] = []

  for (const lender of lenders) {
    const abi = getAbi(lender)
    const callData = buildLenderCall(chainId, lender, account)
    const mappedCalls = callData.map((call) => ({ call, abi }))
    calls = [...calls, ...mappedCalls]
  }

  const rawResults = await multicallViem(
    chainId,
    calls.flatMap((call) => call.abi),
    calls.map((call) => call.call),
    0,
  )

  const lenderData: { [lender: string]: any } = {}

  let currentSlice = 0
  for (const lender of lenders) {
    const [converter, sliceLength] = fetchAndConvertDataSlice(lender, chainId, account, prices)

    const data = rawResults.slice(currentSlice, currentSlice + sliceLength)
    const convertedData = converter(data)
    if (convertedData) {
      lenderData[lender] = convertedData
    }

    currentSlice += sliceLength
  }

  return lenderData
}

export const fetchAureliusUserData: AsyncThunk<
  AureliusUserReserveResponse,
  AureliusUserReservesQueryParams,
  any
> = createAsyncThunk<AureliusUserReserveResponse, AureliusUserReservesQueryParams>(
  'aurelius/fetchAureliusUserData',

  async ({ chainId, account, prices }) => {
    if (!account || chainId !== SupportedChainId.MANTLE) {
      return { chainId, tokensData: {}, lendRewards: 0, allowances: {}, account: '' }
    }

    try {
      const lenderData = await getLenderData(chainId, [Lender.AURELIUS], account, prices)
      return lenderData[Lender.AURELIUS]
    } catch (error) {
      console.error('Error fetching Aurelius user data:', error)
      return {
        data: {},
        config: {},
        chainId,
      }
    }
  }
)
