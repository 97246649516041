import { getChainInfoOrDefault } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import { useCallback, useMemo } from 'react'
import { useCurrencyBalanceString } from 'state/connection/hooks'
import { useAppDispatch } from 'state/hooks'
import { updateSelectedWallet } from 'state/user/reducer'
import { shortenAddress } from 'utils'
import StatusIcon, { WalletIcon } from '../Identicon/StatusIcon'
import { getConnection } from 'connection'
import { useSelectChainNoWallet } from 'hooks/useSelectChain'
import { removeConnectedWallet } from 'state/wallets/reducer'
import { getIsCoinbaseWallet, getIsMetaMaskWallet } from 'connection/utils'
import { isMobile } from 'utils/userAgent'
import { setAccount } from 'state/globalNetwork/actions'
import { useChainId } from 'state/globalNetwork/hooks'
import { trackWalletDisconnected } from 'utils/analytics'
import { HStack, VStack, Text, Divider, Box, Flex, Spinner } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { DisconnectButton } from 'components/Button/Action/DisconnectButton'
import { WalletTableMenu } from './WalletMenuAssets'
import { Connector } from '@web3-react/types'
import { CopyButton } from 'components/Button/Action/CopyButton'
import { usePrices } from 'state/oracles/hooks'
import { WRAPPED_NATIVE_ASSET } from 'types/1delta'
import { ModalCloseButton } from 'components/Button/Action/ModalCloseButton'
import { useWalletMenuDisclosure } from 'state/application/hooks'
import { useIsMobile } from 'hooks/useIsMobile'

interface AuthenticatedHeaderProps {
  account: string
  chainId: number
  connector: Connector
  openOptions: () => void
}

const AuthenticatedHeader: React.FC<AuthenticatedHeaderProps> = ({ account, chainId, connector, openOptions }) => {
  const appChainId = useChainId()
  const dispatch = useAppDispatch()

  const isMetaMask = getIsMetaMaskWallet()
  const isCoinbaseWallet = getIsCoinbaseWallet()
  const isInjectedMobileBrowser = (isMetaMask || isCoinbaseWallet) && isMobile

  const balanceString = useCurrencyBalanceString()
  const {
    nativeCurrency: { symbol: nativeCurrencySymbol },
    explorer,
  } = getChainInfoOrDefault(chainId ? chainId : SupportedChainId.MAINNET)

  const connectionType = getConnection(connector).type
  const [nativeCurrencyPrice] = usePrices([WRAPPED_NATIVE_ASSET[chainId]]) || 0

  const selectChain = useSelectChainNoWallet()

  const disconnect = useCallback(() => {
    const walletType = getConnection(connector).getName()
    if (connector.deactivate) {
      connector.deactivate()
    }
    connector.resetState()

    dispatch(updateSelectedWallet({ wallet: undefined }))
    dispatch(removeConnectedWallet({ account, walletType }))
    dispatch(setAccount({ account: undefined }))
    selectChain(appChainId)
    if (openOptions) openOptions()
    trackWalletDisconnected()
  }, [connector, dispatch, openOptions, account, selectChain, appChainId])

  const amountUSD = useMemo(() => {
    const price = parseFloat(nativeCurrencyPrice?.toFixed(5) ?? 0)
    const balance = parseFloat(balanceString || '0')
    return price * balance
  }, [balanceString, nativeCurrencyPrice])

  const { onCloseWalletMenu } = useWalletMenuDisclosure()
  const sizeIsMobile = useIsMobile()

  return (
    <VStack gap="1rem" height="100%" justifyContent="space-between" p="1.5rem" pb="0.5rem">
      <VStack w="100%" gap="0">
        <HStack w="100%" justifyContent="space-between" alignItems={'flex-start'}>
          {balanceString ? (
            <VStack alignItems={'flex-start'} w="100%">
              <HStack>
                <Text
                  color={validateColor('Text/Headings & Titles/Title-text')}
                  style={getTypography('Typography/Sub-Headings/Bold/Sub-Heading 3')}
                  lineHeight={1}
                >
                  {balanceString}
                </Text>
                <Text
                  color={validateColor('Text/Lables/Label-text-default')}
                  style={getTypography('Typography/Sub-Headings/Normal/Sub-Heading 3')}
                  lineHeight={'1 !important'}
                >
                  {nativeCurrencySymbol}
                </Text>
              </HStack>
              <Text
                color={validateColor('Text/Subheadings & Sub Titles/Sub-headings')}
                style={getTypography('Typography/Small/Normal/Small 1')}
              >
                ${amountUSD.toFixed(2)} USD
              </Text>
            </VStack>
          ) : (
            <Spinner
              size={'lg'}
              color={validateColor('Text/Lables/Lable-success')}
              thickness="5px"
              mr="auto"
              mb="1.25rem"
            />
          )}
          {sizeIsMobile && <ModalCloseButton onClick={onCloseWalletMenu} />}
        </HStack>

        <Box w="100%" py="1rem">
          <Divider borderColor={validateColor('Borders/Border-tertiary')} opacity={1} />
        </Box>

        <VStack w="100%" gap="0.5rem" alignItems={'flex-start'}>
          <Text
            style={getTypography('Typography/Small/Normal/Small 1')}
            color={validateColor('Text/Subheadings & Sub Titles/Sub-headings')}
          >
            Balances
          </Text>
          <WalletTableMenu account={account} chainId={chainId} />
        </VStack>
      </VStack>

      <HStack w="100%" justifyContent="space-between">
        <HStack w="100%">
          <Flex>
            <StatusIcon size={32} />
            <Box
              position="relative"
              borderRadius="full"
              bg={validateColor('Surface/Surface-primary 2')}
              marginTop={'auto'}
              marginRight={'-0.75rem'}
              left={'-12px'}
              top="0px"
              p="0.25rem"
            >
              <WalletIcon connectionType={connectionType} w="0.8rem" h="0.8rem" />
            </Box>
          </Flex>
          <Text
            color={validateColor('Text/Headings & Titles/Title-text') + ' !important'}
            style={getTypography('Typography/Body-Labels/Normal/Body')}
          >
            {account && shortenAddress(account, 3)}
          </Text>
          <CopyButton text={account || ''} />
        </HStack>
        <DisconnectButton onClick={disconnect} aria-label="disconnect" />
      </HStack>
    </VStack>
  )
}

export default AuthenticatedHeader
