import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { SerializedBigNumber } from 'types/1delta'
import { Call, multicallViem } from 'utils/multicall'
import INIT_PUBLIC_LENS_ABI from 'abis/init/initLens.json'
import { addressesInitCore } from 'hooks/1delta/addressesInit'
import { INIT_MODES, SupportedChainId } from 'constants/chains'
import { addressToAsset } from 'hooks/1delta/addressesTokens'
import { TOKEN_META } from 'constants/1delta'
import { convertRateToApr, parseRawAmount } from 'utils/tableUtils/prices'
import { RewardsMap } from 'types/lenderData/base'

interface InitPoolReserveResponse {
  data: {
    [tokenSymbol: string]: {
      // reserve market data
      unbacked?: number
      depositRate: number
      averageStableBorrowRate?: number
      liquidityIndex?: SerializedBigNumber
      variableBorrowIndex?: SerializedBigNumber
      lastUpdateTimestamp?: number

      config: {
        [modeId: number]: {
          modeId: number
          // collateral factors
          borrowCollateralFactor: number
          collateralFactor: number
          borrowFactor: number
        }
      }

      // rewards
      rewards?: RewardsMap

      // reserve config
      decimals?: number
      reserveFactor?: SerializedBigNumber
      usageAsCollateralEnabled?: boolean
      stableBorrowRateEnabled?: boolean

      // frozen
      isActive?: boolean
      isFrozen?: boolean
    }
  }
  chainId: number
}

interface InitReservesQueryParams {
  chainId: number
  prices: { [asset: string]: number }
  stakingYields: { [asset: string]: number; }
}

const filtredAsset = ''

export const fetchInitPublicData: AsyncThunk<InitPoolReserveResponse, InitReservesQueryParams, any> =
  createAsyncThunk<InitPoolReserveResponse, InitReservesQueryParams>(
    'lender-init/fetchInitPublicData',
    async ({ chainId, prices, stakingYields }) => {

      if (chainId !== SupportedChainId.MANTLE) return {
        data: {},
        chainId,
      }

      const lensAddress = addressesInitCore.PublicLens[chainId]
      const calls: Call[] = INIT_MODES.map((mode) => {
        return {
          address: lensAddress,
          name: 'getMarketData',
          params: [mode],
        }
      })

      let multicallResult: any[]
      try {
        if (calls.length > 0)
          multicallResult = await multicallViem(
            chainId,
            [...INIT_PUBLIC_LENS_ABI,],
            [
              ...calls,
            ],
            1 // secondary
          )
        else
          multicallResult = []
      } catch (err) {
        console.log('error', err)
        multicallResult = []
      }

      const modeOne = 0
      let initData = {}
      try {
        initData = Object.assign(
          {}, ...multicallResult[modeOne]?.filter(
            // filter USDe
            dat => {
              return dat?.underlying.toLowerCase() !== filtredAsset.toLowerCase()
            }
          ).map(
            dat => {
              const asset = addressToAsset(chainId, dat?.underlying)
              const meta = TOKEN_META[asset]
              const totalDeposits = parseRawAmount(dat?.totalSupply.toString(), meta.decimals)
              const totalDebt = parseRawAmount(dat?.totalDebt.toString(), meta.decimals)
              const price = prices[asset]
              const liquidity = totalDeposits - totalDebt
              return {
                [asset]: {
                  borrowCap: parseRawAmount(dat?.borrowCap.toString(), meta.decimals),
                  canBurn: Boolean(dat?.canBurn),
                  canFlash: Boolean(dat?.canFlash),
                  canMint: Boolean(dat?.canMint),
                  canRepay: Boolean(dat?.canRepay),
                  underlying: parseData(dat?.underlying),
                  totalDeposits,
                  totalDebt,
                  totalDepositsUSD: price * totalDeposits,
                  totalDebtUSD: price * totalDebt,
                  totalLiquidity: liquidity,
                  totalLiquidityUSD: liquidity * price,
                  depositRate: convertRateToApr(parseRawAmount(dat?.supplyRate.toString(), 18)),
                  variableBorrowRate: convertRateToApr(parseRawAmount(dat?.borrowRate.toString(), 18)),
                  stableBorrowRate: 0,
                  stakingYield: stakingYields[asset] ?? 0,
                  rewards: {},
                  config: {},
                  borrowingEnabled: totalDebt !== 0
                }
              }
            }
          )
        )

        INIT_MODES.forEach((mode, index) => {
          multicallResult[index]?.filter(
            // filter USDe
            multicallData => multicallData?.underlying.toLowerCase() !== filtredAsset.toLowerCase()
          )?.forEach(multicallData => {
            const asset = addressToAsset(chainId, multicallData?.underlying)
            const cf = parseRawAmount(multicallData.collateralFactor, 18)
            initData[asset].config[mode] = {
              modeId: mode,
              borrowCollateralFactor: cf,
              collateralFactor: cf,
              borrowFactor: parseRawAmount(multicallData.borrowFactor, 18),
              maxHealthAfterLiq: parseRawAmount(multicallData.maxHealthAfterLiq, 18),
            }
          });
        });
      } catch (e: any) {
        initData = {}
        console.log("error composing init data", e)
      }
      return {
        data: initData,
        chainId,
      }
    }
  )

const parseData = (d: any) => {
  return d?.toString()
}