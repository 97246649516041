import { AAVE_MAINNET_CHAINS, MAINNET_CHAINS } from 'constants/1delta'
import { SupportedChainId } from 'constants/chains'
import { SupportedAssets } from 'types/1delta'
import { FlatAddressDictionary } from '../1delta/addresses'
import { addressesTokens } from '../1delta/addressesTokens'
import { Lender } from 'types/lenderData/base'
import { getLenderAssets } from 'constants/getAssets'

export const getLenderTokenAddresses = (chainId: number, protocol = Lender.AAVE_V3): FlatAddressDictionary => {
  return Object.assign(
    {},
    ...getLenderAssets(chainId, protocol).filter(a => a !== SupportedAssets.ETH && a !== SupportedAssets.MATIC).map((asset) => {
      return { [asset]: addressesTokens[asset][chainId] }
    })
  )

}

export const getAllTokenAddresses = (chainId: number): FlatAddressDictionary => {
  if (MAINNET_CHAINS.includes(chainId)) {
    return Object.assign(
      {},
      ...Object.values(SupportedAssets).filter(a => a !== SupportedAssets.ETH && a !== SupportedAssets.MATIC && Boolean(addressesTokens[a]?.[chainId])).map((asset) => {
        return { [asset]: addressesTokens[asset][chainId] }
      })
    )
  }
  return {}
}

export const getAaveV3TokenAddresses = (chainId: number): FlatAddressDictionary => {
  if (AAVE_MAINNET_CHAINS.includes(chainId)) {
    return Object.assign(
      {},
      ...getLenderAssets(chainId, Lender.AAVE_V3).map((asset) => {
        return { [asset]: addressesTokens[asset][chainId] }
      })
    )
  }
  return {}
}

export const getLendleTokenAddresses = (chainId: number): FlatAddressDictionary => {
  if (chainId !== SupportedChainId.MANTLE) return {}
  return Object.assign(
    {},
    ...getLenderAssets(chainId, Lender.LENDLE).map((asset) => {
      return { [asset]: addressesTokens[asset][chainId] }
    })
  )
}

export const getAaveV2TokenAddresses = (chainId: number): FlatAddressDictionary => {
  if (chainId !== SupportedChainId.POLYGON) return {}
  return Object.assign(
    {},
    ...getLenderAssets(chainId, Lender.AAVE_V2).map((asset) => {
      return { [asset]: addressesTokens[asset][chainId] }
    })
  )
}

export const getAureliusTokenAddresses = (chainId: number): FlatAddressDictionary => {
  if (chainId !== SupportedChainId.MANTLE) return {}
  return Object.assign(
    {},
    ...getLenderAssets(chainId, Lender.AURELIUS).map((asset) => {
      return { [asset]: addressesTokens[asset][chainId] }
    })
  )
}

export const getMeridianTokenAddresses = (chainId: number): FlatAddressDictionary => {
  if (chainId !== SupportedChainId.TAIKO) return {}
  return Object.assign(
    {},
    ...getLenderAssets(chainId, Lender.MERIDIAN).map((asset) => {
      return { [asset]: addressesTokens[asset][chainId] }
    })
  )
}

export const getCompoundV3TokenAddresses = (chainId: number): FlatAddressDictionary => {
  if (chainId === SupportedChainId.POLYGON)
    return Object.assign(
      {},
      ...getLenderAssets(SupportedChainId.POLYGON, Lender.COMPOUND_V3).map((asset) => {
        return { [asset]: addressesTokens[asset][SupportedChainId.POLYGON] }
      })
    )

  return {}
}

export const getAllLenderTokenAddresses = (chainId: number): FlatAddressDictionary => {
  if (MAINNET_CHAINS.includes(chainId)) {
    return Object.assign(
      {},
      ...Object.values(SupportedAssets).filter(a => a !== SupportedAssets.ETH && a !== SupportedAssets.MATIC
      ).filter(a => Boolean(addressesTokens[a]?.[chainId]) // filter assets without addresses
      ).map(
        (asset) => {
          return { [asset]: addressesTokens[asset]?.[chainId] }
        }
      )
    )
  } else return {}
}
