import { HStack, Icon, Img, Tooltip } from '@chakra-ui/react'
import { Currency } from '@1delta/base-sdk'
import { AssetFlags, getAssetFlagIcon } from 'constants/1delta'
import { useAssetFlags } from 'hooks/asset/useAssetFlags'
import { tokenToAsset } from 'hooks/1delta/addressesTokens'
import { Droplet } from 'react-feather'
import { validateColor } from 'theme/colors'

interface AssetStatusIndicatorProps {
  currency: Currency
}

export const AssetStatusIndicator: React.FC<AssetStatusIndicatorProps> = ({ currency }) => {
  const asset = tokenToAsset(currency)
  const { frozen, reachedSupplyCap, reachedBorrowCap, lowLiquidity } = useAssetFlags(asset)
  return (
    <HStack alignItems="center" gap="0.1rem">
      {frozen && (
        <Tooltip label="This asset is frozen.">
          <Img src={getAssetFlagIcon(AssetFlags.FROZEN)} alt="Frozen" w="15px" h="15px" />
        </Tooltip>
      )}
      {reachedSupplyCap && (
        <Tooltip label="This asset has almost reached its supply cap.">
          <Img src={getAssetFlagIcon(AssetFlags.SUPPLY_CAP)} alt="Supply cap reached" w="15px" h="15px" />
        </Tooltip>
      )}
      {reachedBorrowCap && (
        <Tooltip label="This asset has almost reached its borrow cap.">
          <Img src={getAssetFlagIcon(AssetFlags.BORROW_CAP)} alt="Borrow cap reached" w="15px" h="15px" />
        </Tooltip>
      )}
      {lowLiquidity && (
        <Tooltip label="This asset has almost no liquidity left.">
          <Icon color={validateColor('Text/Lables/Lable-error')} w="15px" h="15px">
            <Droplet />
          </Icon>
        </Tooltip>
      )}
    </HStack>
  )
}
