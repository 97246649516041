import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { getAddressesForChainIdFromAssetDict } from 'hooks/1delta/addresses'
import { Call, multicallViem } from 'utils/multicall'
import AAVE_POOL_ABI from 'abis/aave/AavePoolV3Upgraded.json'
import AAVE_POOL_DATA_PROVIDER_ABI from 'abis/aave/AaveV3ProtocolDataProvider.json'
import {
  addressesAaveATokens
} from 'hooks/1delta/addressesAave'
import { BPS, TOKEN_META } from 'constants/1delta'
import { parseRawAmount } from 'utils/tableUtils/prices'
import { EModeData } from 'types/lenderData/aave-v3'
import { getAaveV3TokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { formatAaveRawApyToApr } from 'utils/1delta/generalFormatters'
import { getLenderAssets } from 'constants/getAssets'
import { Lender, RewardsMap } from 'types/lenderData/base'
import { getAaveTypePoolAddress, getAaveTypePoolDataProviderAddress } from 'hooks/1delta'

/** Indexes for accessing structs */

enum AaveV3GetReservesIndexes {
  unbacked = 0,
  accruedToTreasuryScaled = 1,
  totalAToken = 2,
  totalStableDebt = 3,
  totalVariableDebt = 4,
  liquidityRate = 5,
  variableBorrowRate = 6,
  stableBorrowRate = 7,
  averageStableBorrowRate = 8,
  liquidityIndex = 9,
  variableBorrowIndex = 10,
  lastUpdateTimestamp = 11
}

enum AaveV3GetreserveConfigDataIndexes {
  decimals = 0,
  ltv,
  liquidationThreshold,
  liquidationBonus,
  reserveFactor,
  usageAsCollateralEnabled,
  borrowingEnabled,
  stableBorrowRateEnabled,
  isActive,
  isFrozen,
}

/** Names for the emode struct query */

enum AaveV3GetEModeCategoryDataIndexes {
  ltv = 'ltv',
  liquidationThreshold = 'liquidationThreshold',
  liquidationBonus = 'liquidationBonus',
  priceSource = 'priceSource',
  label = 'label'
}

interface AaveV3ReserveResponse {
  data: {
    [tokenSymbol: string]: {
      // token amounts
      totalDeposits: number;
      totalDebtStable: number;
      totalDebt: number;
      // USD amounts
      totalDepositsUSD: number;
      totalDebtStableUSD: number;
      totalDebtUSD: number;
      // rates
      depositRate: number;
      variableBorrowRate: number;
      stableBorrowRate: number;
      averageStableBorrowRate: number;
      // misc
      liquidityIndex: string;
      variableBorrowIndex: string;
      lastUpdateTimestamp: number;
      // rewards
      rewards?: RewardsMap
    }
  }
  config: {
    [tokenSymbol: string]: {
      decimals: number;

      config: {
        [modeId: number]: {
          modeId: number
          // collateral factors
          borrowCollateralFactor: number
          collateralFactor: number
          borrowFactor: 1
        }
      }

      // flags
      collateralActive: boolean;
      borrowingEnabled: boolean;
      hasStable: boolean;
      isActive: boolean;
      isFrozen: boolean;

      // eMode
      eMode: {
        label: string;
        category: number;
        borrowCollateralFactor: number;
        collateralFactor: number;
        priceSource: string;
      };

      // caps
      borrowCap: number;
      supplyCap: number;
      debtCeiling: number;
    }
  }
  chainId: number
  eModes: {
    [mode: number]: EModeData
  }
}

export interface AAVEPoolV3ReservesQueryParams {
  chainId: number
  prices: { [asset: string]: number }
  stakingYields: { [asset: string]: number }
}

export const EMODES = [0, 1, 2, 3, 4]

export const fetchAaveV3PublicData: AsyncThunk<AaveV3ReserveResponse, AAVEPoolV3ReservesQueryParams, any> =
  createAsyncThunk<AaveV3ReserveResponse, AAVEPoolV3ReservesQueryParams>(
    'aave-v3/fetchAaveV3PublicData',

    async ({ chainId, prices, stakingYields }) => {
      const providerAddress = getAaveTypePoolDataProviderAddress(chainId, Lender.AAVE_V3)
      const tokenDict = getAaveV3TokenAddresses(chainId)
      const poolAddress = getAaveTypePoolAddress(chainId, Lender.AAVE_V3)
      const assetsToQuery = getLenderAssets(chainId, Lender.AAVE_V3)

      const assets = assetsToQuery.map((a) => tokenDict[a])

      const names = Object.keys(tokenDict)

      const calls: Call[] = assets.map((tk) => {
        return {
          address: providerAddress,
          name: 'getReserveData',
          params: [tk],
        }
      })

      const callReservesList: Call = {
        address: poolAddress,
        name: 'getReservesList',
        params: [],
      }

      const aTokenNames = Object.keys(getAddressesForChainIdFromAssetDict(addressesAaveATokens, chainId))
      const callsConfig: Call[] = assets.map((tk) => {
        return {
          address: providerAddress,
          name: 'getReserveConfigurationData',
          params: [tk],
        }

      })

      const getEModeCategoryData: Call[] = EMODES.flatMap((eMode) => {
        return [
          {
            address: poolAddress,
            name: 'getEModeCategoryData',
            params: [eMode],
          },
          {
            address: poolAddress,
            name: 'getEModeCategoryBorrowableBitmap',
            params: [eMode],
          },
          {
            address: poolAddress,
            name: 'getEModeCategoryCollateralBitmap',
            params: [eMode],
          }
        ]
      })

      const callsIsolated: Call[] = assets.map((tk) => {
        return {
          address: providerAddress,
          name: 'getSiloedBorrowing',
          params: [tk],
        }
      })

      const callsCaps: Call[] = assets.map((tk) => {
        return {
          address: providerAddress,
          name: 'getReserveCaps',
          params: [tk],
        }
      })

      const callsCeilings: Call[] = assets.map((tk) => {
        return {
          address: providerAddress,
          name: 'getDebtCeiling',
          params: [tk],
        }
      })
      const callDecimals: Call = {
        address: providerAddress,
        name: 'getDebtCeilingDecimals',
        params: [],
      }


      let multicallResult: any[]
      try {
        if (calls.length > 0)
          multicallResult = await multicallViem(
            chainId,
            [...AAVE_POOL_DATA_PROVIDER_ABI, ...AAVE_POOL_ABI],
            [
              ...calls, ...callsConfig,
              ...getEModeCategoryData, ...callsIsolated, ...callsCaps,
              ...callsCeilings, callDecimals, callReservesList
            ],
            1
          )
        else
          multicallResult = []
      } catch (err) {
        console.log('error', err)
        multicallResult = []
      }
      const multicallResultReserves = multicallResult.slice(0, calls.length)
      const resultReserves: any = Object.assign(
        {},
        ...multicallResultReserves.map((entry: any, index) => {
          const asset = names[index]
          const decimals = TOKEN_META[asset]?.decimals ?? 18
          const totalDeposits = parseRawAmount(entry?.[AaveV3GetReservesIndexes.totalAToken].toString(), decimals)
          const totalDebtStable = parseRawAmount(entry?.[AaveV3GetReservesIndexes.totalStableDebt].toString(), decimals)
          const totalDebt = parseRawAmount(entry?.[AaveV3GetReservesIndexes.totalVariableDebt].toString(), decimals)
          const liquidity = totalDeposits - totalDebt - totalDebtStable
          const price = prices[asset] ?? 1
          return {
            [asset]: {
              // raw amounts
              totalDeposits,
              totalDebtStable,
              totalDebt,
              totalLiquidity: liquidity,
              // USD amounts
              totalDepositsUSD: totalDeposits * price,
              totalDebtStableUSD: totalDebtStable * price,
              totalDebtUSD: totalDebt * price,
              totalLiquidityUSD: liquidity * price,
              // rates
              depositRate: formatAaveRawApyToApr(entry?.[AaveV3GetReservesIndexes.liquidityRate].toString()),
              variableBorrowRate: formatAaveRawApyToApr(entry?.[AaveV3GetReservesIndexes.variableBorrowRate].toString()),
              stableBorrowRate: formatAaveRawApyToApr(entry?.[AaveV3GetReservesIndexes.stableBorrowRate].toString()),

              // rewards
              rewards: {},

              stakingYield: stakingYields[asset] ?? 0,
            },
          }
        })
      )

      const multicallResultConfig = multicallResult.slice(
        calls.length,
        calls.length + callsConfig.length
      )

      const emodeDataResult = multicallResult.slice(
        calls.length + callsConfig.length,
        calls.length + callsConfig.length + getEModeCategoryData.length
      )

      const capsResult = multicallResult.slice(
        calls.length + callsConfig.length + getEModeCategoryData.length + callsIsolated.length,
        calls.length + callsConfig.length + getEModeCategoryData.length + callsIsolated.length + callsCaps.length
      )

      const ceilingResult = multicallResult.slice(
        calls.length + callsConfig.length + getEModeCategoryData.length + callsIsolated.length + callsCaps.length,
        multicallResult.length - 2
      )

      const decimalsResult = multicallResult[multicallResult.length - 2]
      const reservesList = multicallResult[multicallResult.length - 1]

      let eModeConfigs = {}
      let collateralBitmap = {}
      let debtBitmap = {}
      EMODES.map((mode, index) => {
        const rawCfg = emodeDataResult[3 * index]
        if (mode === 0 || rawCfg.label !== '')
          eModeConfigs[mode] = {
            category: mode,
            label: rawCfg.label,
            borrowCollateralFactor: rawCfg.ltv / BPS,
            collateralFactor: rawCfg.liquidationThreshold / BPS,
            borrowFactor: 1
          }
        debtBitmap[mode] = emodeDataResult[3 * index + 1]
        collateralBitmap[mode] = emodeDataResult[3 * index + 2]
      })

      // we get the reserves list to obtain the index per reserve
      const lowerReservesList = (reservesList as string[]).map(a => a.toLowerCase())
      const resultConfig: any = Object.assign(
        {},
        ...multicallResultConfig.map((entry: any, index) => {
          // get the eMode from the reserev according to the  pool logic
          const activeEmodes = EMODES.map(mode => isReserveEnabledOnBitmap(debtBitmap[mode], lowerReservesList.indexOf(assets[index].toLowerCase())) ? mode : -1).filter(m => m > 0)
          const eModeCategory = activeEmodes.length === 0 ? 0 : activeEmodes[0] // Number(emodeResult[index].toString())
          return {
            [aTokenNames[index]]: {
              decimals: Number(entry?.[AaveV3GetreserveConfigDataIndexes.decimals]),

              config: {
                ...populateEModes(Number(entry?.[AaveV3GetreserveConfigDataIndexes.ltv]) / BPS, Number(entry?.[AaveV3GetreserveConfigDataIndexes.liquidationThreshold]) / BPS),
                ...(eModeCategory !== 0 ? {
                  [eModeCategory]: {
                    modeId: eModeCategory,
                    borrowCollateralFactor: eModeConfigs[eModeCategory].borrowCollateralFactor,
                    collateralFactor: eModeConfigs[eModeCategory].collateralFactor,
                    borrowFactor: 1
                  }
                } : {})

              },

              // flags
              collateralActive: entry?.[AaveV3GetreserveConfigDataIndexes.usageAsCollateralEnabled],
              borrowingEnabled: entry?.[AaveV3GetreserveConfigDataIndexes.borrowingEnabled],
              hasStable: entry?.[AaveV3GetreserveConfigDataIndexes.stableBorrowRateEnabled],
              isActive: entry?.[AaveV3GetreserveConfigDataIndexes.isActive],
              isFrozen: entry?.[AaveV3GetreserveConfigDataIndexes.isFrozen],

              // eMode
              eMode: eModeConfigs[eModeCategory],

              // caps
              borrowCap: Number(capsResult[index][0]?.toString()),
              supplyCap: Number(capsResult[index][1]?.toString()),
              debtCeiling: parseRawAmount(ceilingResult[index]?.toString(), Number(decimalsResult?.toString()))
            },
          }
        })
      )

      return {
        data: resultReserves,
        config: resultConfig,
        chainId,
        eModes: eModeConfigs
      }
    }
  )

const populateEModes = (borrowCollateralFactor: number, collateralFactor: number) => {
  return Object.assign({}, ...EMODES.map(e => {
    return {
      [e]: {
        modeId: e,
        borrowCollateralFactor,
        collateralFactor,
        borrowFactor: 1
      }
    }
  }))
}

/** Replica of aave's bitmap checker */
function isReserveEnabledOnBitmap(
  bitmap: bigint,
  reserveIndex: number
): boolean {
  return ((bitmap >> BigInt(reserveIndex)) & 1n) !== 0n;

}