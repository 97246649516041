
import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: `0x${string}` }

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap("0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f")
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')

// Uniswap interface Multicalls
const CELO_MULTICALL_ADDRESS = '0x633987602DE5C4F337e3DbF265303A1080324204'
const BNB_MULTICALL_ADDRESS = '0x963Df249eD09c358A4819E39d9Cd5736c3087184'
const MANTLE_MULTICALL_ADDRESS = '0x2A51f2F647E1883F88C992244dEd171238693107'
const ARBITRUM_ONE_MULTICALL_ADDRESS = '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB'
const ARBITRUM_RINKEBY_MULTICALL_ADDRESS = '0xa501c031958F579dB7676fF1CE78AD305794d579'
const LINEA_MULTICALL_ADDRESS = '0xac1cE734566f390A94b00eb9bf561c2625BF44ea'
const AVALANCHE_MULTICALL_ADDRESS = '0x0139141Cd4Ee88dF3Cdb65881D411bAE271Ef0C2'
const BASE_MULTICALL_ADDRESS = '0x091e99cb1C49331a94dD62755D168E941AbD0693'
const BLAST_MULTICALL_ADDRESS = '0xdC7f370de7631cE9e2c2e1DCDA6B3B5744Cf4705'
const TAIKO_MULTICALL_ADDRESS = '0x8E24CfC19c6C00c524353CB8816f5f1c2F33c201'

/**
 * Uniswap Interface Multicall
 */
export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.OPTIMISM,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.ARBITRUM_ONE]: ARBITRUM_ONE_MULTICALL_ADDRESS,
  [SupportedChainId.ARBITRUM_RINKEBY]: ARBITRUM_RINKEBY_MULTICALL_ADDRESS,
  [SupportedChainId.BASE]: BASE_MULTICALL_ADDRESS,
  [SupportedChainId.CELO]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.BSC]: BNB_MULTICALL_ADDRESS,
  [SupportedChainId.MANTLE]: MANTLE_MULTICALL_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_MULTICALL_ADDRESS,
  [SupportedChainId.LINEA]: LINEA_MULTICALL_ADDRESS,
  [SupportedChainId.AVALANCHE]: AVALANCHE_MULTICALL_ADDRESS,
  [SupportedChainId.TAIKO]: TAIKO_MULTICALL_ADDRESS,
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const META_AGGREGATOR_ADDRESSES: AddressMap = {
  [SupportedChainId.MANTLE]: '0xCc0264D9235756950613C770455424f9F7294A1f',
}