import { createReducer } from '@reduxjs/toolkit'
import { resetAaveV3UserState, resetState } from './actions'
import { fetchAaveV3PublicData } from './fetchPublicData'
import { fetchAaveV3UserData } from './fetchUserData'
import { AaveV3State } from 'types/lenderData/aave-v3'
import { fetchAaveV3Allowances } from './fetchAllowances'
import { STATE_CHAINIDS } from 'constants/chains'

const emptyData = {
  publicLoaded: false,
  lenderData: {},
  userData: {},
  userConfigs: {},
  selectedConfig: {
    id: '',
    mode: 0,
    data: {}
  },
  aprData: {},
  balanceData: {}
}

export const initialState: AaveV3State = Object.assign(
  {}, ...STATE_CHAINIDS.map(cId => { return { [cId]: emptyData } })
)

export default createReducer<AaveV3State>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    .addCase(resetAaveV3UserState, (state, action) => {
      const chainId = action.payload.chainId
      if (state[chainId])
        state[chainId].userData = {}
    })
    // new reserve data-fetch using aave data provider
    .addCase(fetchAaveV3PublicData.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      const chainId = action.payload.chainId
      if (assetKeys.length === 0) return; // prevents setting load flags
      if (!state[chainId]) state[chainId] = emptyData
      // assign public data
      for (let i = 0; i < assetKeys.length; i++) {
        state[chainId].lenderData[assetKeys[i]] = {
          ...state[chainId].lenderData[assetKeys[i]],
          ...action.payload.data[assetKeys[i]],
          ...action.payload.config[assetKeys[i]],
        }
      }
      state[chainId].publicLoaded = true
      // assign eModes
      state[chainId].selectedConfig.data = action.payload.eModes
    })
    .addCase(fetchAaveV3PublicData.pending, (state) => {
      //
    })
    // user data from provider
    .addCase(fetchAaveV3UserData.fulfilled, (state, action) => {
      let assetKeys = Object.keys(action.payload.tokensData)
      const { chainId, account } = action.payload
      if (!state[chainId]) state[chainId] = emptyData
      const mode = action.payload.userEMode
      // organic yields
      let depositInterest = 0
      let borrowInterest = 0
      // rewards
      let rewardDepositAccrual = 0
      let rewardBorrowAccrual = 0
      // staking
      let stakingDepositAccrual = 0
      let stakingBorrowAccrual = 0
      // amountrs
      let deposits = 0
      let debt = 0
      let collateral = 0
      let borrowDiscountedCollateral = 0
      // main user data
      for (let i = 0; i < assetKeys.length; i++) {
        const asset = assetKeys[i]
        if (!state[chainId].lenderData[asset]) continue;
        const { depositsUSD, debtStableUSD, debtUSD, collateralActive } = action.payload.tokensData[asset]
        const {
          depositRate, stakingYield, variableBorrowRate, stableBorrowRate,
          rewards
        } = state[chainId].lenderData[asset]
        // amounts
        deposits += depositsUSD
        debt += debtStableUSD
        debt += debtUSD
        // rewards 
        Object.values(rewards ?? {}).map(rewardData => {
          rewardDepositAccrual += rewardData.depositRate * depositsUSD
          rewardBorrowAccrual += rewardData.variableBorrowRate * debtUSD + rewardData.stableBorrowRate * debtStableUSD
        })
        // staking
        stakingDepositAccrual += (stakingYield ?? 0) * depositsUSD
        stakingBorrowAccrual += (stakingYield ?? 0) * (debtStableUSD + debtUSD)
        if (collateralActive) {
          // risk adjusted
          collateral += (state[chainId].lenderData[asset].config[mode]?.collateralFactor ?? 1) * depositsUSD
          borrowDiscountedCollateral += (state[chainId].lenderData[asset].config[mode]?.borrowCollateralFactor ?? 1) * depositsUSD
        }
        // IRs
        depositInterest += depositRate * depositsUSD
        borrowInterest += debtStableUSD * stableBorrowRate + debtUSD * variableBorrowRate

        if (!state[chainId].userData[account]) state[chainId].userData[account] = {}
        // add data to the state
        state[chainId].userData[account][asset] = {
          ...state[chainId].userData[account][asset],
          ...action.payload.tokensData[asset],
        }
      }
      const nav = deposits - debt
      // aggregated balance data
      state[chainId].balanceData[account] = {
        borrowDiscountedCollateral,
        collateral,
        deposits,
        debt,
        adjustedDebt: debt,
        nav
      }
      if (!state[chainId].aprData) state[chainId].aprData = {}
      // aggregated apr data
      state[chainId].aprData[account] = {
        apr: (depositInterest - borrowInterest) / nav,
        borrowApr: borrowInterest / debt,
        depositApr: depositInterest / deposits,
        rewardApr: (rewardDepositAccrual + rewardBorrowAccrual) / nav,
        rewardDepositApr: rewardDepositAccrual / deposits,
        rewardBorrowApr: rewardBorrowAccrual / debt,
        stakingApr: (stakingDepositAccrual - stakingBorrowAccrual) / nav,
        stakingDepositApr: stakingDepositAccrual / deposits,
        stakingBorrowApr: stakingBorrowAccrual / debt,
      }
      // state[chainId].lenderRewards = action.payload.lendRewards
      if (!state[chainId].userConfigs) state[chainId].userConfigs = {}
      state[chainId].userConfigs[account] = { selectedMode: mode, id: account }
    })
    .addCase(fetchAaveV3UserData.pending, (state) => {
      //
    })
    // ALLOWANCES
    .addCase(fetchAaveV3Allowances.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.allowances)
      const { chainId, account } = action.payload
      for (let i = 0; i < assetKeys.length; i++) {
        const asset = assetKeys[i]
        if (!state[chainId].userData[account]?.[asset]) continue;
        state[chainId].userData[account][asset] = {
          ...state[chainId].userData[account][asset],
          ...action.payload.allowances[asset]
        }
      }
    })
    .addCase(fetchAaveV3Allowances.pending, (state) => {
      //
    })
)