import { Grid, HStack } from '@chakra-ui/layout'
import { validateColor } from 'theme/colors'
import { Image } from '@chakra-ui/image'
import { NavigationButton } from 'components/Button/Action/NavigationButton'
import { Notifications } from 'components/Menu/Notifications'
import { Settings } from 'components/Menu/Settings/GeneralSettings'
import { useHistory, useLocation } from 'react-router-dom'
import { useIsMobile } from 'hooks/useIsMobile'
import { NavigationMenu } from 'components/Menu/NavigationMenu'
import { isProductionEnv } from 'utils/env'
import NetworkSelector from 'components/Menu/NetworkSelector'
import Web3Status from 'components/Menu/Web3Status'
import { useUserTheme } from 'state/user/hooks'
import { oneDeltaLogo, oneDeltaLogoSmall } from 'constants/1delta'
import { getTypography } from 'theme/typographies'
import { NewTag } from 'components/Pills/NewTag'
import { trackPageViewed } from 'utils/analytics'
import { Page } from 'utils/analytics/types'
import { useIsTablet } from 'hooks/useIsTablet'

export const pages = {
  '/markets': Page.MARKETS,
  '/swap': Page.SWAP,
  '/margin': Page.MARGIN,
  '/yields': Page.YIELDS,
  '/bridge': Page.BRIDGE,
  '/easymode': Page.EASYMODE,
}

export const Navbar: React.FC = () => {
  const { pathname } = useLocation()
  const history = useHistory()

  const selectPath = (path: string) => !pathname.includes(path) && history.push(path)

  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const showMobileMenu = isMobile || isTablet

  const theme = useUserTheme()

  const logo = oneDeltaLogo[theme]
  const logoSmall = oneDeltaLogoSmall[theme]

  const handleClick = (path: string) => {
    trackPageViewed(pages[path])
    selectPath(path)
  }

  return (
    <Grid
      w="100%"
      padding={showMobileMenu ? '1rem' : '1rem 1.5rem'}
      alignItems="center"
      templateColumns="1fr 1fr"
      backgroundColor={validateColor('Surface/Surface-primary')}
    >
      <HStack gap={'2.5rem'}>
        <Image src={showMobileMenu ? logoSmall : logo} w={showMobileMenu ? '2rem' : '6rem'} alt="1delta Logo" />
        {!showMobileMenu ? (
          <HStack gap={'2rem'} w="fit-content" alignItems={'center'} alignSelf={'stretch'}>
            <NavigationButton selected={pathname.includes('/markets')} onClick={() => handleClick('/markets')}>
              <NewTag
                text="Markets"
                style={
                  pathname.includes('/markets')
                    ? getTypography('Typography/Body-Labels/Bold/Body-Label')
                    : getTypography('Typography/Body-Labels/Normal/Body')
                }
              />
            </NavigationButton>
            <NavigationButton selected={pathname.includes('/swap')} onClick={() => handleClick('/swap')}>
              Swap
            </NavigationButton>
            <NavigationButton selected={pathname.includes('/margin')} onClick={() => handleClick('/margin')}>
              Margin
            </NavigationButton>
            {!isProductionEnv() && (
              <NavigationButton selected={pathname.includes('/easymode')} onClick={() => handleClick('/easymode')}>
                EasyMode
              </NavigationButton>
            )}
            <NavigationButton selected={pathname.includes('/yields')} onClick={() => handleClick('/yields')}>
              Yields
            </NavigationButton>
            {!isProductionEnv() && (
              <NavigationButton selected={pathname.includes('/bridge')} onClick={() => selectPath('/bridge')}>
                Bridge
              </NavigationButton>
            )}
            {!isProductionEnv() && (
              <NavigationButton selected={pathname.includes('/vote')} onClick={() => handleClick('/vote')}>
                Vote
              </NavigationButton>
            )}
          </HStack>
        ) : null}
      </HStack>

      {/* <SmartSearchBar /> */}

      <HStack flex="1" gap={showMobileMenu ? '0.5rem' : '1rem'} justifyContent="flex-end">
        {!pathname.includes('/yields') && !pathname.includes('/markets') && (
          <NetworkSelector isMobile={showMobileMenu} />
        )}

        <Web3Status isMobile={showMobileMenu} />

        <Notifications theme={theme} />

        <Settings theme={theme} />

        {showMobileMenu && <NavigationMenu pathname={pathname} selectPath={selectPath} />}
      </HStack>
    </Grid>
  )
}
